import React, {useState} from "react";
import { Box, Button, Card, Stack, TextField, Zoom, Paper} from "@mui/material";
import {Link} from "react-router-dom";
import { styled } from '@mui/material/styles';
import axiosInstance from "../AxiosInstance/axios";
import DoneIcon from '@mui/icons-material/Done';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(""); 
    const [success, setSuccess] = useState(0); // [0, 1, -1] = [waiting, success, error]
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSuccess(0);

        if (validateEmail(email)) {

            try {
                await axiosInstance.post('/api/passwordReset/',  
                    {email: email.toLowerCase()},
                    {headers: {'Content-Type': 'application/json'},
                }).then(response => {
                    console.log(response);
                });
                setMessage("Password reset email sent. Please check your email.")
                setSuccess(1);
            } catch (error) {
                console.error(error);
                if (error.response.status === 400) {
                    setMessage('No user found with that email address.');
                } else {
                    setMessage('An error occurred. Please try again later or contact administrator.');
                }
                setSuccess(-1);
            }

        } else {
            setMessage("Invalid email address. Please try again.");
            setSuccess(-1);
        }
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    return (
        <Zoom in={true}>
                <Box sx={{padding: "90px", backgroundColor: "#F5F5F5"}}>
                    <Card variant="outlined" className="login-form" justify="center" sx={{maxWidth: "1000px", margin: "auto", border: "none !important"}}>
                        
                            {success !== 1 && (
                                <div>
                                    <h1 style={{marginBottom: "50px"}}>Reset Password</h1>

                                    {success === -1 && (
                                        <h2 style={{color:"red"}}>{message}</h2>
                                    )}

                                    <div>
                                        <form onSubmit={handleSubmit} className="text-fields">
                                            <Stack spacing={{xs: 1}}>
                                                <Item>Email*</Item>
                                                <TextField required type={"text"} style={{marginBottom: "25px"}} fullWidth
                                                        onChange={e => setEmail(e.target.value)}></TextField>
                                                <div style={{textAlign: "center"}}>
                                                    <Button variant={"outlined"} sx={{color: "#333333", fontWeight: "400", width: "40%", textTransform: "none",}} 
                                                    type="submit">Reset Password</Button>
                                                </div>

                                            </Stack>
                                        </form>
                                    </div>

                                    <div style={{marginTop: "25px"}}>
                                        <Link to={"/register"}>No account? No problems, just register!</Link>
                                    </div>

                                </div>
                            )}

                            {success === 1 && (
                                <div>
                                    <h1>{message}</h1>
                                    <br />
                                    <DoneIcon sx={{ fontSize: "150px", marginBottom: "30px" }} />
                                    <br />
                                    <br />
                                </div>
                            )}
                        
                    </Card>
                </Box>
        </Zoom>

    );
}

export default ForgotPassword;