import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import {Button, Paper, Stack, Card, TextField, Autocomplete, Alert, AlertTitle } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CSVLink } from "react-csv";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminExpertsForCalls = () => {

    const [requestsExperts, setRequestsExperts] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [csvExperts, setCSVExperts] = useState([])

    const [addExpert, setAddExpert] = useState(0);
    const [isAddExpertButtonClicked,setIsAddExpertButtonClicked] = useState(false);

    const [descOfComptence, setDescOfComptence] = useState("");
    const [descOfUsefulness, setDescOfUsefulness] = useState("");
    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedCall, setSelectedCall] = useState([]);

    const [listOfUsers, setListOfUsers] = useState([]);
    const [matchmakerCalls, setMatchmakerCalls] = useState([]);

    const [formErrors, setFormErrors] = useState([]);
    const formErrorRef = useRef(null);

    const [success, setSuccess] = useState(0);

    const registerExpert = () => {

        if (validateFields()) {
            setAddExpert(0)
            setIsAddExpertButtonClicked(false)

            const data ={
                user: selectedUser,
                title: selectedCall,
                description: descOfComptence,
                expertise: descOfUsefulness
            }

            axiosInstance.post("/api/register/registerExpertiseForCall", data, {headers: {'Content-Type': 'application/json'}}, {withCredentials: true})
            .then((response) => {
                if (response.status === 200) {
                    setSuccess(1)
                }
                console.log(response)
                getMatchmakerRequests()
            }, error => {
                console.log(error)
                setSuccess(-1)
            })
        } 
        
    }

    function validateFields() {
        setFormErrors([])
        let errors = []

        // Input Validation

        // If errors occurred in the fields then return false and set the errors to be displayed to user
        if (errors.length > 0) {
            setFormErrors(errors);
            return false;
        } else {
            return true;
        }
    }

    const getMatchmakerRequests = () => {
        axiosInstance.get("api/register/getCallProposalsAndExpertiseRequests")
        .then((res) => {
            setRequestsExperts(res.data.experts)

            const tempCleanedExperts = []

            Promise.all((res.data.experts).map(fExpert => cleanExpertCall(fExpert)))
            .then((cleanedExperts) => {
                tempCleanedExperts.push(...cleanedExperts);
                setCSVExperts(cleanCSVExperts(tempCleanedExperts))
            })

        })
    }

    const getUserDetails = (userEmail) => {
        return axiosInstance.get("api/generic/userDetails/" + userEmail)
        .then((res) => {
            return res.data
        })
    }

    const cleanExpertCall = async (expert) => {
        const data = {}
        const userDetails = {}

        userDetails.userDet = await getUserDetails(expert.user)

        data.name = userDetails.userDet.firstName + " " + userDetails.userDet.lastName
        data.institution = userDetails.userDet.workplace
        data.pools = userDetails.userDet.researchPool
        data.keywordsFromProfile = userDetails.userDet.interests.join(', ')
        data.descOfCompetence = expert.description_of_competence
        data.descOfUsefulness = expert.description_of_usefulness
        data.country = userDetails.userDet.country
        data.email = userDetails.userDet.email
        data.linkedIn = userDetails.userDet.linkedIn
        data.profession = userDetails.userDet.profession
        data.notes = expert.notes

        return data
    }

    const cleanCSVExperts = (tempExperts) => {
        const tempCSVExpertsArray = []

        for (let i = 0; i < tempExperts.length; i++) {
            const tempCSVExperts = {
                "name": tempExperts[i].name,
                "institution": tempExperts[i].institution,
                "pools": tempExperts[i].pools,
                "keywordsFromProfile": tempExperts[i].keywordsFromProfile,
                "descOfCompetence": tempExperts[i].descOfCompetence,
                "descOfUsefulness": tempExperts[i].descOfUsefulness,
                "country": tempExperts[i].country,
                "email": tempExperts[i].email,
                "linkedIn": tempExperts[i].linkedIn === "" ? "N/A" : tempExperts[i].linkedIn,
                "profession": tempExperts[i].profession,
            }
            tempCSVExpertsArray.push(tempCSVExperts)
        }
        
        return tempCSVExpertsArray
    }

    let expertCSVHeaders = [
        { label: "Name", key: "name" },
        { label: "Institution", key: "institution" },
        { label: "Pools", key: "pools" },
        { label: "Keywords From Profile", key: "keywordsFromProfile" },
        { label: "Description of Competence", key: "descOfCompetence" },
        { label: "Description of Usefulness", key: "descOfUsefulness" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "LinkedIn", key: "linkedIn" },
        { label: "Profession", key: "profession" },
    ]

    const getMatchmakerCalls = () => {
        axiosInstance.get("api/register/details/GetCalls")
        .then((res) => {
          setMatchmakerCalls(res.data.calls)
        })
    }

    const getUsers = () => {
        axiosInstance.get("api/register/getUsers")
        .then((res) => {
          setListOfUsers(res.data.users)
        })
    }

    const checkCallExist = () => {
        if (matchmakerCalls.length === 0) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        getMatchmakerRequests()
        getMatchmakerCalls()
        getUsers()
    }, [])

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let columns = [
        {
            name: 'Call Title',
            selector: row=> row.call,
            sortable: true,
        },
        {
            name: 'User',
            selector: row=> row.user,
            sortable: true,
        },
        {
            name: 'Details',
            
            cell: row => (
                <>
                    <CSVLink data={csvExperts} headers={expertCSVHeaders} filename={"Experts of " + row.call + ".csv"}><Button variant="contained" sx={{background:"green", marginRight: "8px"}}>csv</Button></CSVLink>
                    <Button variant="contained" color="primary" onClick={() => handleClickOpen(row)}>more</Button>
                </>
            )
        }
    ];

    const customStyles = {
        rows: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
            },
        },
        cells: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
            },
        },
        headCells: {
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
    };

    return (
        <div>
            <GreyBox>
                <br />
                <Item sx={{textAlign: "center"}}><h1>Experts for calls:</h1></Item>
                <div style={{ padding: '0 5vw' }}>
                    <DataTable
                        columns={columns}
                        data={requestsExperts}
                        customStyles={customStyles}
                    />
                </div>

                <br/>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Details</DialogTitle>
                    <DialogContent>
                        <Item><h1>Call Title: {selectedRow?.call}</h1></Item>
                        <Item><h1>User: {selectedRow?.user}</h1></Item>
                        <Item><h1>Description of Competence: {selectedRow?.description_of_competence}</h1></Item>
                        <Item><h1>Description of Usefulness: {selectedRow?.description_of_usefulness}</h1></Item>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>

                <div style={{display: "flex", alignItems:"center", flexDirection:"column"}}>
                    {success === 1 && (
                        <Alert severity="success" style={{textAlign: "left"}}>
                            <AlertTitle>Success</AlertTitle>
                            Expert registered successfully.
                        </Alert>
                    )}

                    {success === -1 && (
                        <Alert severity="Error" style={{textAlign: "left"}}>
                            <AlertTitle>Error</AlertTitle>
                            An error occurred trying to register the expert. Please contact the administrator.
                        </Alert>
                    )}

                    {!isAddExpertButtonClicked && (
                        <Button onClick={() => {setAddExpert(1); setIsAddExpertButtonClicked(true);setSuccess(0);}}>
                            Create & Assign an Expert
                        </Button>
                    )}
                </div>

                <div style={{display: "flex", justifyContent:"center"}}>

                    {addExpert === 1 && !checkCallExist() && (
                        <Alert severity="error" style={{textAlign: "left"}}>
                            <AlertTitle>Error</AlertTitle>
                            No calls exist to assign an expert to. Please {<Link to="/admin/registerResearchCall">create</Link>} a call first.
                        </Alert>
                    
                    )}

                    {addExpert === 1 && checkCallExist() && (
                        
                            <Stack spacing={{xs: 1}}>

                                <Item><h2>Register a new Expert:</h2></Item>
                                
                                {formErrors.length > 0 && 
                                    <Alert ref={formErrorRef} severity="error" style={{textAlign: "left"}}> 
                                        <AlertTitle>Error</AlertTitle>
                                        The following errors occured trying to create a call:
                                        <ul>
                                            {formErrors.map((error,index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                }

                                <Item>User to become an expert*:</Item>
                                <Autocomplete
                                    disablePortal
                                    id="autocomplete-user"
                                    options={listOfUsers.map(user => user.email)}
                                    onChange={(event, newValue) => {setSelectedUser(newValue)}}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <Item>Call to become an expert for*:</Item>
                                <Autocomplete
                                    disablePortal
                                    id="autocomplete-user"
                                    options={matchmakerCalls.map(call => call.title)}
                                    onChange={(event, newValue) => {setSelectedCall(newValue)}}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <Item>Description of Competence*:</Item>
                                <TextField 
                                    fullWidth
                                    multiline
                                    rows={2}
                                    className='label'
                                    onChange={e => setDescOfComptence(e.target.value)} required type="text" 
                                />

                                <Item>Description of Usefulness*:</Item>
                                <TextField 
                                    fullWidth
                                    multiline
                                    rows={2}
                                    className='label'
                                    onChange={e => setDescOfUsefulness(e.target.value)} required type="text" 
                                />

                                <Button onClick={() => {registerExpert()}}>
                                Submit
                                </Button>
                            </Stack>
                        
                    )}
                </div>

               
                {/* Old Card System:
                <div>
                    {requestsExperts.map((item, key) => {
                        return (
                        <div style={{"border": "1px solid black", "width": "30%", "border-radius": "20px", "padding": "1em", "margin": "auto", "marginBottom": "1em"}}>
                        <p>{item.call}</p>
                        <p>{item.user}</p>
                        <p>{item.description_of_competence}</p>
                        <p>{item.description_of_usefulness}</p>
                        </div>
                    )})}
                </div>
                */}
               <br />
            </GreyBox>

        </div>
        
    )
}

export default AdminExpertsForCalls;