import MyCoffeeBreaks from "./MyCoffeeBreaks";


const CoffeeBreakHolder = () =>{
    return (
        <>
            <MyCoffeeBreaks/>
        </>
    )
}

export default CoffeeBreakHolder