import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Logout from "./Pages/Logout";
import ForgotPassword from "./Pages/ForgotPassword";
import CoffeeBreakSignUp from "./Pages/CoffeeBreakSignUp";
import CoffeeBreakPage from "./Pages/CoffeeBreakPage";
import {ProtectedRoute} from "./Components/Common/ProtectedRoute";
import {AdminProtectedRoute} from "./Components/Common/AdminProtectedRoute"
import ProposalRegistration from './Components/Matchmaker/ProposalRegistration';
import MatchMakerProposals from './Components/Matchmaker/MatchMakerProposals';
import IntentRegistration from './Components/Matchmaker/IntentRegistration';
import UserDashboard from './Pages/UserDashboard';
import MyProfile from './Pages/MyProfile';
import AdminPage from './Pages/AdminPage'
import AdminRegisterResearchCall from './Pages/AdminRegisterResearchCall';
import AdminAllResearchCalls from './Pages/AdminAllResearchCalls';
import AdminProposalsForCalls from './Pages/AdminProposalsForCalls';
import AdminStats from './Pages/AdminStats';
import AdminExpertsForCalls from './Pages/AdminExpertsForCalls';
import MatchMakerSubmissionForm from './Components/Matchmaker/MatchMakerSubmissionForm'
import RegistrationConfirmed from './Components/Common/RegistrationConfirmed';
import PasswordChanged from './Components/Common/PasswordChanged';
import AdminCurrentResearchCall from './Components/Matchmaker/AdminCurrentResearchCall';
import AdminEditResearchCall from './Pages/AdminEditResearchCall';

const Router = ({isLoggedIn, setLoggedIn}) => {
    return (
        <Routes>
            <Route path="/" element={<Register/>}/>
            <Route path="login/" element={<Login/>}/>
            <Route path="register/" element={<Register/>}/>
            <Route path="forgotPassword/" element={<ForgotPassword/>}/>
            <Route path="/logout" element={<Logout isLoggedIn={isLoggedIn} />}/>
            <Route path="/registrationconfirmed/:uidb64/:activationCode" element={<RegistrationConfirmed />} />
            <Route path="/passwordchanged/:uidb64/:activationCode" element={<PasswordChanged />} />
            <Route path="/proposalregistration" element={<ProtectedRoute><ProposalRegistration /></ProtectedRoute>}/>
            <Route path="/intentRegistration" element={<ProtectedRoute><IntentRegistration /></ProtectedRoute>}/>
            <Route path="/coffeeBreaks" element={<ProtectedRoute><CoffeeBreakPage/></ProtectedRoute>}/>
            <Route path="/coffeebreakSignUp" element={<ProtectedRoute><CoffeeBreakSignUp/></ProtectedRoute>}/>
            <Route path="/userdashboard" element={<ProtectedRoute><UserDashboard/></ProtectedRoute>}/>
            <Route path="/myprofile" element={<ProtectedRoute><MyProfile/></ProtectedRoute>}/>
            <Route path="/matchmaker" element={<ProtectedRoute><MatchMakerProposals/></ProtectedRoute>}/>
            <Route path="/matchmakerform/:title" element={<ProtectedRoute><MatchMakerSubmissionForm/></ProtectedRoute>}/>
            <Route path="/admin" element={<AdminProtectedRoute><AdminPage /></AdminProtectedRoute>} />
            <Route path="/admin/registerResearchCall" element={<AdminProtectedRoute><AdminRegisterResearchCall /></AdminProtectedRoute>} />
            <Route path="/admin/proposalsForCalls" element={<AdminProtectedRoute><AdminProposalsForCalls /></AdminProtectedRoute>} />
            <Route path="/admin/expertsForCalls" element={<AdminProtectedRoute><AdminExpertsForCalls /></AdminProtectedRoute>} />
            <Route path="/admin/currentResearchCalls" element={<AdminProtectedRoute><AdminAllResearchCalls /></AdminProtectedRoute>} />
            <Route path="/admin/currentResearchCalls/:title" element={<AdminProtectedRoute><AdminCurrentResearchCall /></AdminProtectedRoute>} />
            <Route path="/admin/editResearchCall/:title" element={<AdminProtectedRoute><AdminEditResearchCall /></AdminProtectedRoute>} />
            <Route path="/admin/stats" element={<AdminProtectedRoute><AdminStats /></AdminProtectedRoute>} />
        </Routes>
    );
}

export default Router;
