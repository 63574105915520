import Button from "@mui/material/Button";
import axiosInstance from "../../AxiosInstance/axios";
import {Card, CardActionArea, CardContent, Rating, Stack} from "@mui/material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import CoffeeBreakFeedbackDialog from "./CoffeeBreakFeedbackDialog";
import {useState} from "react";
import convertIsoToLocalTime from "../../util/TimeUtil";
import Item from "../Common/Item";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const HistoricMeetingSummary = ({meetingDetails, counter, callbackFn}) =>{
    const [isDialogOpen, setDialogOpen] = useState(false)

    function handleDialogClose(){
        setDialogOpen(false)
    }


    return(
        <Card sx={{ width: 350 }}>
            <CardActionArea>
                <CardContent>
                    <Item>
                        <h4>Connection {counter}</h4>
                        <TaskAltIcon sx={{color:"#4DB0F4", fontSize: 60}}/>
                        {meetingDetails.rating && <Rating value={meetingDetails.rating} readOnly/>}
                        <p><strong>Topic:</strong> {meetingDetails.meetingAbout}</p>
                        <p><strong>Countries:</strong> {meetingDetails.countries.map((country)=><span key={country}>{country}  </span>)}</p>
                        <p><strong>Accepted by:</strong> {meetingDetails.otherPersonsName} <br/>{meetingDetails.otherPersonsWorkplace}</p>
                        {meetingDetails.otherPersonsLinkedIn && <p><strong>LinkedIn:</strong> <a href={meetingDetails.otherPersonsLinkedIn}>{meetingDetails.otherPersonsLinkedIn}</a></p>}
                        {meetingDetails.otherPersonsEmail && <p><strong>E-mail:</strong> <a href={"mailto:"+meetingDetails.otherPersonsEmail}>{meetingDetails.otherPersonsEmail}</a></p>}
                        <Stack spacing={1}>
                            <Button size="small"
                            variant="outlined"
                            sx={{my: 3, width: "100%", textTransform: 'none', padding:"0px 9px !important", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'flex', marginRight: "10px"}} onMouseDown={event => event.stopPropagation()} onClick={()=>{setDialogOpen(true)}}><FeedbackIcon sx={{marginRight: "8px"}}/> <p>Provide feedback</p></Button>
                        </Stack>
                    </Item>
                </CardContent>
            </CardActionArea>
            <CoffeeBreakFeedbackDialog handleClose={handleDialogClose} open={isDialogOpen} meetingId={meetingDetails.id} callBackFn={callbackFn}/>
        </Card>
    )
}

export default HistoricMeetingSummary