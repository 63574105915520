import Button from "@mui/material/Button";
import {
    Alert,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, FormGroup, Rating
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import axiosInstance from "../../AxiosInstance/axios";
import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackIcon from '@mui/icons-material/Feedback';

const CoffeeBreakFeedbackDialog = ({open, handleClose, meetingId, callBackFn}) =>{

    const [meetingDetails, setMeetingDetails] = useState({})
    const [loadingError, setLoadingError] = useState(null)


    useEffect(()=>{
        if(open){
            getMeetingDetails()
        }
    }, [open])

    function changeFormValue(key, value){
         setMeetingDetails({...meetingDetails, [key]:value})
    }


    async function getMeetingDetails(){
        axiosInstance.get("/api/coffeeBreak/manageDetailsForMeeting", {params:{"meetingId": meetingId}}).then(response=>{
            setLoadingError(null)
            if(response.data.length===1){
                setMeetingDetails(response.data[0])
            }else{
                setLoadingError("An error has occured, meeting cannot be found! If the error persist please contact us")
            }
            }
        ).catch(err=>{
            console.error(err)
            setLoadingError("An error has occurred while loading the details for this CoffeeBreak. Please try again later or contact us...\n\n"+err)
        })
    }

    function handleSubmit(){
        let dataToPost = meetingDetails
        if(dataToPost.rating===0){
            dataToPost.rating=null
        }
        axiosInstance.post("/api/coffeeBreak/manageDetailsForMeeting", dataToPost, {headers: {'Content-Type': 'application/json'}},
                        {withCredentials: true}).then(response=>{
                            handleClose()
                            callBackFn()
                        }).catch(err=>{
                            window.alert("Error while saving, please try again... If it does not work please wait a bit, refresh the page and try again.")
                            console.error(err)
                        })

    }

    return (
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle><FeedbackIcon/> Feedback form</DialogTitle>
            <DialogContent>
                {loadingError &&
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <Alert severity="error">{loadingError}</Alert>
                    </div>
                }
                {Object.keys(meetingDetails).length>0 && !loadingError &&
                    <div>
                    <DialogContentText>
                        Meeting summary
                    </DialogContentText>
                    <p>
                        <b>Participant</b>: {meetingDetails.otherPersonsName} <br/>
                        (E-mail: <a href={"mailto:"+meetingDetails.otherPersonsEmail}>{meetingDetails.otherPersonsEmail}</a>)<br/> {meetingDetails.otherPersonsLinkedIn && <>LinkedIn: <a href={meetingDetails.otherPersonsLinkedIn}>{meetingDetails.otherPersonsLinkedIn}</a><br/></>}
                        <b>Topic:</b> {meetingDetails.meetingAbout}
                    </p>
                    <DialogContentText>
                        Feedback
                    </DialogContentText>
                            <div>
                                <DialogContentText>
                                    Please rate the platform and provide feedback
                                </DialogContentText>
                                <a href="https://www.surveymonkey.com/r/riscoffeebreaks">
                                    <Typography component="legend">Please rate the experience with our service</Typography>
                                </a>
                            </div>
                    </div>
                }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant={"outlined"} color={"error"}><CloseIcon/> Exit</Button>
                {Object.keys(meetingDetails).length>0 &&
                    <Button onClick={handleSubmit} variant={"contained"} color={"success"}><DoneIcon/> Save</Button>
                }
            </DialogActions>
          </Dialog>
        </div>
  );
}

export default CoffeeBreakFeedbackDialog