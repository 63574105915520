import React, {useEffect, useState} from "react";
import {
    Card,
    Zoom, 
    Radio,
    RadioGroup,
    FormControl,
    Autocomplete,
    FormControlLabel,
    FormLabel,
    Stack,
    TextField,
    Button
} from "@mui/material";
import axiosInstance from "../../AxiosInstance/axios";

const ProposalRegistration = () => {

    const [keywords, setKeywords] = useState([])
    const [keyword, setKeyword] = useState([])
    const [principalInvestigator, setPrincipalInvestigator] = useState([])
    const [title, setTitle] = useState([])
    const [description, setDescription] = useState([])

    async function regsiterProposal() {
        let data = {title: title, expertise: keyword, description: description, principalInvestigator: principalInvestigator}

        await axiosInstance.post('/api/registerProposal/',
            data, {
                headers:
                    {'Content-Type': 'application/json'},
            }).then(response => {
                console.log("Success")
                console.log(response)
            },
            error => {
                console.log("Error")
                console.log(error.response)
            }
        )
    }

    useEffect(()=>{
        getDropdownValues("/api/register/details/getKeywords", setKeywords, "keywords")
    }, [])

    function getDropdownValues(endpoint, setterFunction, key){
        axiosInstance.get(endpoint)
          .then(response => {
            // Handle the response data here
            setterFunction(response.data[key])
          })
          .catch(error => {
            // Handle errors here
            console.error('Error:', error);
          });
    }

    return (
        <Zoom in={true}>
            <div style={{margin: "auto", maxWidth: "400px"}}>
                <Card variant="outlined" style={{maxWidth: "400px"}} justify="center">
                    <div>
                        <Stack spacing={2}>
                            <FormControl>
                            <FormLabel>Please confirm whether you are eligible to act as a Principal Investigator</FormLabel>
                            <RadioGroup
                            value={principalInvestigator}
                            onChange={(event, newValue)=>{setPrincipalInvestigator(newValue)}}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                            </FormControl><br/>

                            <TextField 
                            label={"Please provide high level details of your project idea"} 
                            type={"text"} 
                            onChange={event =>{setTitle(event.target.value)}}>
                                </TextField><br/>

                            <Autocomplete
                                        id="autocomplete-keywords"
                                        options={keywords}
                                        groupBy={(option)=>option.category}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        onChange={(event, newValue)=>{setKeyword(newValue.label)}}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="What expertise gap are you trying to fill? (Select all that applies)"
                                            placeholder="Keywords"
                                          />
                                        )}
                                      /><br />
                            <TextField 
                            label={"Provide more specific details of the expertise you are looking for if you have them."} 
                            type={"text"} 
                            onChange={event => {setDescription(event.target.value)}}>
                                </TextField><br/>

                            <Button variant={"outlined"} onClick={() => {
                                        regsiterProposal()
                                    }}>Submit Proposal</Button>
                        </Stack>
                    </div>
                </Card>
            </div>
        </Zoom>
    );
}

export default ProposalRegistration;