import Box from "@mui/material/Box";
import GreyBox from "../Components/Common/GreyBox";
import {Button, Grid, Paper, Stack} from "@mui/material";
import "./CoffeeBreakPage.css";
import Cb1Img from '../Images/coffeebreak/cb1.png'
import Cb2Img from '../Images/coffeebreak/cb2.png'
import {useEffect} from "react";
import {Image} from "@mui/icons-material";
import CoffeeBreakHolder from "../Components/CoffeeBreak/CoffeeBreakHolder";
import {Link} from "react-router-dom";
import CoffeeIcon from "@mui/icons-material/Coffee";
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));

const CoffeeBreakPage = () =>{
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    return(
        <div>
            <GreyBox sx={{ width: '100%'}}>
                <Stack direction="row" spacing={5} className="grey-background" style={{padding: "50px"}}>
                    <Item className="grey-background" style={{textAlign: "center"}}>
                        <h2>Sign up Today</h2>
                        <div className="register-container2">
                            <p className="register-text" style={{textAlign:"left"}}>
                                <br />
                                Working across disciplines and internationally is notoriously difficult and post pandemic we have fewer opportunities for fortuitous encounters that happen during coffee breaks between seminars – the kind of conversations that can, just sometimes lead to something really interesting.
                                <br />
                                <br />
                                The Coffee Break @ RIS enables you to participate in short (15 minute), informal online conversations with researchers in other disciplines from Germany and Scotland.</p>
                        </div>
                        <Button
                            key={"apply"}
                            size="small"
                            variant="outlined"
                            sx={{my: 1, width: "50%" ,textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600"}}
                            component={Link} to={"/coffeebreakSignUp"}
                        >
                            Request Coffee Break
                        </Button>
                    </Item>
                    <Item className="grey-background" style={{textAlign: "left", marginLeft: "0px"}}><img src={Cb1Img} className="Intro_Image" height={"300px"} width={"450px"} alt="Intro_Image"/></Item>
                </Stack>
            </GreyBox>
        </div>
    )
}

export default CoffeeBreakPage