import {useEffect, useState} from "react";
import axiosInstance from "../../AxiosInstance/axios";
import FutureMeetingSummary from "./FutureMeetingSummary";
import {Grid, Pagination, Paper, Stack} from "@mui/material";
import HistoricMeetingSummary from "./HistoricMeetingSummary";
import CoffeeBreakMatch from "./CoffeeBreakMatch";
import Box from "@mui/material/Box";
import OpaquePaper from "../Common/OpaquePaper";
import { styled } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));

const MyCoffeeBreaks = () =>{

    const [historicCoffeeBreaks, setHistoricCoffeeBreaks] = useState([])

    const [upcomingCoffeeBreaks, setUpcomingCoffeeBreaks] = useState([])

    const [matchingCoffeeBreaks, setMatchingCoffeeBreaks] = useState([])

    const [pendingCoffeeBreaks, setPendingCoffeeBreaks] = useState([])


    //Pagination states:

    const itemPerPage = 5

    const [historicCBsPage, setHistoricCBsPage] = useState(1)

    const [historicCBsTotalPages, setHistoricCBsTotalPages]=useState(1)

    const [matchingCBsPage, setMatchingCBsPage] = useState(1)

    const [matchingCBsTotalPages, setMatchingCBsTotalPages] = useState(1)

    useEffect(()=>{
        setHistoricCBsTotalPages(Math.ceil(historicCoffeeBreaks/itemPerPage))
    }, [historicCoffeeBreaks])

    useEffect(()=>{
        setHistoricCBsTotalPages(Math.ceil(matchingCoffeeBreaks/itemPerPage))
    }, [matchingCoffeeBreaks])


    useEffect(()=>{
        getUpcomingCoffeeBreaks()
        getHistoricCoffeeBreaks()
        getMatchingCoffeeBreaks()
        getPendingCoffeeBreaks()

        //TODO: rm
        getLoggedInUserDetails()
    },[])

    async function getLoggedInUserDetails(){
        await axiosInstance.get("/api/generic/userDetails").then((response)=>{
            //console.log(response.data)
        })
    }

    async function getUpcomingCoffeeBreaks(){
        await axiosInstance.get("/api/coffeeBreak/getMyUpcomingCoffeeBreaks").then((response)=>{
            setUpcomingCoffeeBreaks(response.data)
        })
    }

    async function getHistoricCoffeeBreaks(){
        await axiosInstance.get("/api/coffeeBreak/getMyHistoricCoffeeBreaks").then((response)=>{
            setHistoricCoffeeBreaks(response.data)
        })
    }

    async function getMatchingCoffeeBreaks(){
        await axiosInstance.get("/api/coffeeBreak/getMatchingCoffeeBreaks").then((response)=>{
            setMatchingCoffeeBreaks(response.data)
        })
    }

    async function getPendingCoffeeBreaks(){
        await axiosInstance.get("/api/coffeeBreak/getMyPendingCoffeeBreaks").then((response)=>{
            setPendingCoffeeBreaks(response.data)
        })
    }

    function componentCallBackFn(){
        getMatchingCoffeeBreaks()
        getUpcomingCoffeeBreaks()
        getPendingCoffeeBreaks()
        getHistoricCoffeeBreaks()
    }


    return(
        <Box sx={{ width: '100%'}}>
        <Grid container rowSpacing={4} columnSpacing={20} style={{padding:"20px"}}>
            <Grid item xs={12} md={6}>
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <OpaquePaper>
                            <Item>
                                <h2>My Upcoming Coffee Breaks</h2>
                            </Item>
                            {upcomingCoffeeBreaks.length===0?
                                <div>No Upcoming Coffee Breaks found</div>
                                :
                                <Stack spacing={2} alignItems="center">
                                    {upcomingCoffeeBreaks.map((curCoffeeBreak, i)=>(<div key={i}><FutureMeetingSummary meetingDetails={curCoffeeBreak} Icon={GroupsIcon} counter={i+1} callbackFn={componentCallBackFn} cancelCbText={"Meeting didn't happen"}/></div>))}
                                </Stack>
                            }
                        </OpaquePaper>
                    </Grid>
                            
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <OpaquePaper>
                            <Item>
                                <h2>My Pending Coffee Breaks</h2>
                            </Item>
                            {pendingCoffeeBreaks.length===0?
                                <div>No Pending Coffee Breaks</div>
                                :
                                <Stack spacing={2} alignItems="center">
                                    {pendingCoffeeBreaks.map((curCoffeeBreak, i)=>(<div key={i}><FutureMeetingSummary Icon={AccessTimeIcon} meetingDetails={curCoffeeBreak} counter={i+1} callbackFn={componentCallBackFn} cancelCbText={"Cancel request"}/></div>))}
                                </Stack>
                            }
                        </OpaquePaper>
                    </Grid>
                    <Grid item xs={12}>
                        <OpaquePaper>
                            <Item>
                                <h2>Past Coffee Breaks</h2>
                            </Item>
                        {historicCoffeeBreaks.length===0?
                            <div>No Historic Coffee Breaks found</div>
                            :
                            <div>
                                {historicCBsTotalPages>1 &&
                                    <Pagination count={historicCBsTotalPages} page={historicCBsPage} onChange={(event, pageNum)=>setHistoricCBsPage(pageNum)}/>
                                }
                                <Stack spacing={2} alignItems="center">
                                    {historicCoffeeBreaks.slice((historicCBsPage-1)*itemPerPage, (historicCBsPage)*itemPerPage).map((curCoffeeBreak, i)=>(<div key={i}><HistoricMeetingSummary meetingDetails={curCoffeeBreak} counter={i+1} callbackFn={getHistoricCoffeeBreaks}/></div>))}
                                </Stack>
                                {historicCBsTotalPages>1 &&
                                    <Pagination count={historicCBsTotalPages} page={historicCBsPage} onChange={(event, pageNum)=>setHistoricCBsPage(pageNum)}/>
                                }
                                </div>
                        }
                        </OpaquePaper>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
        </Box>
    )
}

export default MyCoffeeBreaks