import {useEffect} from "react"
import axiosInstance from "../AxiosInstance/axios";

const Logout = ({isLoggedIn}) => {
    useEffect(() => {
        if(!isLoggedIn){
            window.location.href = '/'
        }
        (async () => {
            try {
                const {data} = await
                    axiosInstance.post('/api/logout/', {
                            refresh_token: localStorage.getItem('refresh_token')
                        }, {headers: {'Content-Type': 'application/json'}},
                        {withCredentials: true});
                localStorage.clear();
                axiosInstance.defaults.headers.common['Authorization'] = null;
                window.location.href = '/'
            } catch (e) {
                console.error('Logout failed with:', e)
            }
        })();
    }, []);
    return (
        <div></div>
    )
}

export default Logout