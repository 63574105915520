import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import {Button, Card, Stack, TextField, Box, Paper, FormControl, FormControlLabel, FormLabel, Checkbox, Alert, AlertTitle } from "@mui/material";
import { Link } from "react-router-dom"; 
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import './AdminEditResearchCall.css';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminEditResearchCall = () => {

    const { title } = useParams();
    const [newTitle, setTitle] = useState("");
    const [funder, setFunder] = useState("");
    const [fundingAmount, setFundingAmount] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const [closingDate, setclosingDate] = useState("");
    const [thisCall, setThisCall] = useState()

    const [isTitleChanged, setIsTitleChanged] = useState(false)
    const [isFunderChanged, setIsFunderChanged] = useState(false)
    const [isFundingAmountChanged, setIsFundingAmountChanged] = useState(false)
    const [isDescriptionChanged, setIsDescriptionChanged] = useState(false)
    const [isLinkChanged, setIsLinkChanged] = useState(false)
    const [isClosingDateChanged, setIsClosingDateChanged] = useState(false)
    const [isPoolChanged, setIsPoolChanged] = useState(false)
   
    const [pool, setPool] = useState("")
    const [poolKeywords, setPoolKeywords] = useState([])

    const [success, setSuccess] = useState(0);
    const [respData, setRespData] = useState("");
    const [respCode, setRespCode] = useState(0);

    const [formErrors, setFormErrors] = useState([]);
    const formErrorRef = useRef(null);

    const registerProposal = () => {
        
        if (validateFields()) {
            const data = {
            "originalTitle": title,
            "newTitle": newTitle,
            "expertise": pool.split(', '),
            "funder": funder,
            "description": description,
            "link": link,
            "closing_date": closingDate,
            "pools": pool.split(', '),
            "funding_amount": currencySign(fundingAmount),
            }
            axiosInstance.post("api/editCall/", data, {headers: {'Content-Type': 'application/json'}}, {withCredentials: true})
            .then((res) => {
                if(res.status === 200) {
                    setSuccess(1)
                } else {
                    // Report other status codes and their response
                    setRespData(res.response.data)
                    setRespCode(res.status)
                    setSuccess(-1)
                }
            }, (error) => {
                setRespData(error.response.data)
                setRespCode(error.response.status)
                setSuccess(-1)
            })
        }
    }

    const poolsNames = {
      "SAGES": "Scottish Alliance for Geoscience, Environment & Society (SAGES)",
      "Brain Health Arc": "Brain Health ARC Alliance",
      "SCAF": "The Scottish Alliance for Food (SCAF)",
      "ETP": "Scottish Research Partnership for Energy (ETP)",
      "SRPE": "Scottish Research Partership in Engineering (SRPE)",
      "SINAPSE": "Scottish Imaging Network: A platform for Scientific Excellence (SINAPSE)",
      "MASTS": "Marine Alliance for Science and Technology for Scotland (MASTS)",
      "ScotChem": "Scottish Universities Chemistry Research Pool (ScotChem)",
      "SICSA": "Scottish Informatics and Computer Science Alliance (SICSA)",
      "SULSA": "Scottish Universities Life Sciences Alliance (SULSA)",
      "SUPA": "Scottish Universities Physics Alliance (SUPA)",
      "BE-ST": "Built Environment - Smarter Transformation (BE-ST)",
      "Quantum Technology ARC": "Quantum Technology ARC"
    };

    function getPoolKeywordValues(endpoint, setterFunction, key){
      axiosInstance.get(endpoint)
        .then(response => {
          // Handle the response data here
          setterFunction(response.data[key])
        })
        .catch(error => {
          // Handle errors here
          console.error('Error:', error);
        });
    }

    const getSpecificCallDetails = () => {
        axiosInstance.get("api/getSpecificCall/" + encodeURIComponent(title))
        .then((res) => {
            setThisCall(res.data)
            setTitle(res.data.title)
            setDescription(res.data.description)
            setFundingAmount(res.data.funding_amount)
            setFunder(res.data.funder)
            setLink(res.data.link)
            setclosingDate(res.data.closing_date)
            setPool(res.data.expertise)
        })
    }

    useEffect(() => {
      getPoolKeywordValues("/api/register/details/getKeywords", setPoolKeywords, "poolKeywords")
      if (formErrors.length > 0) {
        formErrorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [formErrors])

    useEffect(() => {
        getSpecificCallDetails()
    },[])

    function currencySign(amount) {
        let parts = amount.split('-').map(part => part.trim());
        let formattedParts = parts.map(part => {
            let cleanPart = part.replace(/[^\d.£€$]/g, '');
            let numberPart = Number(cleanPart.replace(/[£€$]/g, ''));
            let formattedPart = numberPart.toLocaleString();
            let currency = cleanPart[0].match(/[£€$]/) ? cleanPart[0] : "£";
            return currency + formattedPart;
        });
        return formattedParts.join(' - ');
    }

    function validateFields() {
        setFormErrors([])
        let errors = []

        // Input Validation Logic:

            // ensure the required fields are not empty
            if (!closingDate ) {
                errors.push("Closing date is required!")
            }

            if (!title) {
                errors.push("Title is required!")
            }
            
            if (!description) {
                errors.push("Description is required!")
            }

            if (pool.length <1) {
                errors.push("At least one research pool must be selected!")
            }

            // ensure link to external website is an actual link
            let urlRegex = /^((http|https):\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,6}(\/[^\s]*)?$/i;
            if (link && !urlRegex.test(link)) {
                errors.push("Link must be a valid URL.");
            }

            let fundingAmountRegex = /^[£€$]?(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?(\s*-\s*[£€$]?(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?)?$/
            if (!fundingAmountRegex.test(fundingAmount)) {
                errors.push("Funding amount must be a number or a range of numbers.")
            }

            // ensure date is in the future
            let currentDate = new Date()
            let selectedDate = new Date(closingDate)
            if (selectedDate < currentDate) {
                errors.push("Closing date cannot be in the past!")
            }

        // If errors occurred in the fields then return false and set the errors to be displayed to user
        if (errors.length > 0) {
            setFormErrors(errors);
            return false;
        } else {
            return true;
        }
        
    }

    return (
        <div>
            <GreyBox>
                {success === 0 && (
                    <Box style={{maxWidth: "500px", margin:"auto"}}>
                        <Card variant="outlined" className="register" sx={{background: `#F5F5F5`, maxWidth: "500px", margin: "auto", border: "none !important"}}>
                            <Stack className='registerProposalForm' spacing={{xs: 1}} >
                                
                                <Stack spacing={2} >
                                    <Item sx={{textAlign: "center"}}><h1>Edit Research Call</h1></Item>

                                    <Item><h2> {title} </h2></Item>
                                    <span style={{display:'flex', justifyContent:'space-between'}}>
                                        <Button 
                                            variant='contained' 
                                            onClick={() => {
                                                setTitle('')
                                                setIsTitleChanged(true)
                                                setFunder('')
                                                setIsFunderChanged(true)
                                                setFundingAmount('')
                                                setIsFundingAmountChanged(true)
                                                setDescription('')
                                                setIsDescriptionChanged(true)
                                                setLink('')
                                                setIsLinkChanged(true)
                                                setclosingDate('')
                                                setIsClosingDateChanged(true)
                                                setPool("")
                                                setIsPoolChanged(true)
                                            }}>
                                            Clear All
                                        </Button>
                                        {(isTitleChanged || isFunderChanged || isFundingAmountChanged || isDescriptionChanged || isLinkChanged || isClosingDateChanged || isPoolChanged) && (
                                            <Button 
                                                variant='contained' 
                                                onClick={() => {
                                                    setTitle(thisCall && thisCall.title ? thisCall.title : "");
                                                    setIsTitleChanged(false);
                                                    setFunder(thisCall && thisCall.funder ? thisCall.funder : "");
                                                    setIsFunderChanged(false);
                                                    setFundingAmount(thisCall && thisCall.funding_amount ? thisCall.funding_amount : "");
                                                    setIsFundingAmountChanged(false);
                                                    setDescription(thisCall && thisCall.description ? thisCall.description : "");
                                                    setIsDescriptionChanged(false);
                                                    setLink(thisCall && thisCall.link ? thisCall.link : "");
                                                    setIsLinkChanged(false);
                                                    setclosingDate(thisCall && thisCall.closing_date ? thisCall.closing_date : "");
                                                    setIsClosingDateChanged(false);
                                                    setPool(thisCall && thisCall.expertise ? thisCall.expertise : "");
                                                    setIsPoolChanged(false);
                                                }}>
                                                Restore Default
                                            </Button>
                                        )}
                                    </span>

                                    {formErrors.length > 0 && 
                                        <Alert ref={formErrorRef} severity="error" style={{textAlign: "left"}}> 
                                            <AlertTitle>Error</AlertTitle>
                                            The following errors occured trying to create a call:
                                            <ul>
                                                {formErrors.map((error,index) => (
                                                    <li key={index}>{error}</li>
                                                ))}
                                            </ul>
                                        </Alert>
                                    }

                                    <Item>Title of the call*</Item>
                                    <TextField 
                                        fullWidth 
                                        onChange={e => {
                                            setTitle(e.target.value);
                                            setIsTitleChanged(e.target.value !== (thisCall && thisCall.title))
                                        }} 
                                        required
                                        type={"text"} 
                                        minRows={2}
                                        multiline
                                        value={newTitle}
                                        className={isTitleChanged ? 'changed' : ''}
                                    />
                                    {isTitleChanged && (
                                        <Button onClick={() => {
                                            setTitle(thisCall && thisCall.title ? thisCall.title : "");
                                            setIsTitleChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {newTitle && (
                                        <Button onClick={() => {setTitle(''); setIsTitleChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}

                                    <Item>Funding amount* <i>(by default £ sign will be used unless alternative currency sign used)</i> </Item>
                                    <TextField 
                                        fullWidth
                                        onChange={e => {
                                            setFundingAmount(e.target.value)
                                            setIsFundingAmountChanged(e.target.value !== (thisCall && thisCall.funding_amount))
                                        }} 
                                        required 
                                        type={"text"} 
                                        value={fundingAmount}
                                        className={isFundingAmountChanged ? 'changed' : ''}
                                    />
                                    {isFundingAmountChanged && (
                                        <Button onClick={() => {
                                            setFundingAmount(thisCall && thisCall.funding_amount ? thisCall.funding_amount : "");
                                            setIsFundingAmountChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {fundingAmount && (
                                        <Button onClick={() => {setFundingAmount(''); setIsFundingAmountChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}

                                    <Item>Description*</Item>
                                    <TextField 
                                        fullWidth  
                                        minRows={5}
                                        multiline
                                        size='medium' 
                                        onChange={e => {
                                            setDescription(e.target.value)
                                            setIsDescriptionChanged(e.target.value !== (thisCall && thisCall.description))
                                        }} 
                                        required
                                        type={"text"}
                                        value={description}
                                        className={isDescriptionChanged ? 'changed' : ''}
                                    />
                                    {isDescriptionChanged && (
                                        <Button onClick={() => {
                                            setDescription(thisCall && thisCall.description ? thisCall.description : "");
                                            setIsDescriptionChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {description && (
                                        <Button onClick={() => {setDescription(''); setIsDescriptionChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}

                                    <Item>Who is the funder?</Item>
                                    <TextField 
                                        fullWidth 
                                        size='medium' 
                                        multiline
                                        onChange={e => {
                                            setFunder(e.target.value)
                                            setIsFunderChanged(e.target.value !== (thisCall && thisCall.funder))
                                        }} 
                                        required 
                                        type={"text"} 
                                        value={funder}
                                        className={isFunderChanged ? 'changed' : ''}
                                    />
                                    {isFunderChanged && (
                                        <Button onClick={() => {
                                            setFunder(thisCall && thisCall.funder ? thisCall.funder : "");
                                            setIsFunderChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {funder && (
                                        <Button onClick={() => {setFunder(''); setIsFunderChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}

                                    <Item>Link to call's external website</Item>
                                    <TextField 
                                        fullWidth 
                                        size='medium' 
                                        onChange={e => {
                                            setLink(e.target.value)
                                            setIsLinkChanged(e.target.value !== (thisCall && thisCall.link))
                                        }} 
                                        required 
                                        type={"text"} 
                                        value={link}
                                        className={isLinkChanged ? 'changed' : ''}
                                    />
                                    {isLinkChanged && (
                                        <Button onClick={() => {
                                            setLink(thisCall && thisCall.link ? thisCall.link : "");
                                            setIsLinkChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {link && (
                                        <Button onClick={() => {setLink(''); setIsLinkChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}
                                    
                                    <Item>Closing date of the call*</Item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={(newValue) => {
                                                setclosingDate(dayjs(newValue).format('YYYY-MM-DD'))
                                                setIsClosingDateChanged(dayjs(newValue).format('YYYY-MM-DD') !== (thisCall && thisCall.closing_date))
                                            }}
                                            value={closingDate ? dayjs(closingDate) : null}
                                            className={isClosingDateChanged ? 'changed' : ''}
                                        />
                                    </LocalizationProvider>
                                    {isClosingDateChanged && (
                                        <Button onClick={() => {
                                            setclosingDate(thisCall && thisCall.closing_date ? thisCall.closing_date : "");
                                            setIsClosingDateChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {closingDate && (
                                        <Button onClick={() => {setclosingDate(''); setIsClosingDateChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}
                                    
                                    <FormControl style={{ alignItems: "flex-start" }}>
                                        <FormLabel>What research pools, networks or partnerships, are participating in supporting matchmaking for the call? *</FormLabel>
                                        {Object.keys(poolKeywords).map((tempPool) => (
                                            <div key={tempPool}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={pool.includes(tempPool)}
                                                            onChange={() => {
                                                                const poolArray = pool ? pool.split(', ') : [];
                                                                if (poolArray.includes(tempPool)) {
                                                                    const newPool = poolArray.filter((value) => value !== tempPool).join(', ');
                                                                    setPool(newPool);
                                                                    setIsPoolChanged(newPool !== thisCall.expertise);
                                                                } else {
                                                                    const newPool = poolArray.length > 0 ? [...poolArray, tempPool].join(', ') : tempPool;
                                                                    setPool(newPool);
                                                                    setIsPoolChanged(newPool !== thisCall.expertise);
                                                                }
                                                            }}
                                                          
                                                        />
                                                    }
                                                    style={{textAlign: 'left'}}
                                                    label={poolsNames[tempPool]}
                                                />
                                                
                                            </div>
                                        ))}
                                    </FormControl>
                                    {isPoolChanged && (
                                        <Button onClick={() => {
                                            setPool(thisCall && thisCall.expertise ? thisCall.expertise : "");
                                            setIsPoolChanged(false);
                                        }}>
                                            Revert
                                        </Button>
                                    )}
                                    {pool && (
                                        <Button onClick={() => {setPool(""); setIsPoolChanged(true)}}>
                                            Clear
                                        </Button>
                                    )}

                                    <Item><Button variant={"outlined"} 
                                    sx={{color: "#333333", fontWeight: "400", width: "100%", textTransform: "none",}} 
                                    onClick={() => {registerProposal()}}>Submit</Button>
                                    </Item>

                                    <Item>
                                        <Link to="/admin">
                                            <Button 
                                                variant={"outlined"}
                                                sx={{color: "#333333", fontWeight: "400", width: "100%", textTransform: "none",}}
                                            >
                                                Back
                                            </Button>
                                        </Link>
                                    </Item>
                                </Stack>
                            </Stack>
                        </Card>
                    </Box>
                )}

                {success === -1 && (
                    <div> 
                        <h1>Error Code: {respCode}</h1>
                        <h2>Please contact us and send us the below error message, along with the error code:</h2>
                        <p>{respData}</p>
                    </div>
                )}

                {success === 1 && (
                    <div>
                        <Item sx={{textAlign: "center"}}><h1>Call has been edited successfully!</h1></Item>
                        <Item sx={{textAlign: "center"}}><h2>This is what you changed:</h2></Item>
                        <div style={{display:'flex', flexDirection:'column', alignContent: 'center', alignItems:'center', justifyContent:'center'}}>

                            {isTitleChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Title: <span className='old-fields'>{thisCall.title}</span></h3></Item>
                                    <Item><h3>New Title: <span className='new-fields'>{title}</span></h3></Item>
                                </>            
                            )}
                            {isFunderChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Funder: <span className='old-fields'>{thisCall.funder}</span></h3></Item>
                                    <Item><h3>New Funder: <span className='new-fields'>{funder}</span></h3></Item>
                                </>            
                            )}
                            {isFundingAmountChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Funding Amount: <span className='old-fields'>{thisCall.funding_amount}</span></h3></Item>
                                    <Item><h3>New Funding Amount: <span className='new-fields'>{fundingAmount}</span></h3></Item>
                                </>            
                            )}
                            {isDescriptionChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Description: <span className='old-fields'>{thisCall.description}</span></h3></Item>
                                    <Item><h3>New Description: <span className='new-fields'>{description}</span></h3></Item>
                                </>            
                            )}
                            {isLinkChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Link: <span className='old-fields'>{thisCall.link}</span></h3></Item>
                                    <Item><h3>New Link: <span className='new-fields'>{link}</span></h3></Item>
                                </>            
                            )}
                            {isClosingDateChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Closing Date: <span className='old-fields'>{thisCall.closing_date}</span></h3></Item>
                                    <Item><h3>New Closing Date: <span className='new-fields'>{closingDate}</span></h3></Item>
                                </>            
                            )}
                            {isPoolChanged && (
                                <>
                                    <Item><h3 className='old-fields-headers'>Old Research Pool: <span className='old-fields'>{thisCall.expertise}</span></h3></Item>
                                    <Item><h3>New Research Pool: <span className='new-fields'>{pool}</span></h3></Item>
                                </>            
                            )}
  
                        </div>
                        
                        <br />
                        <Link to="/admin/currentResearchCalls">
                            <Button>View current research calls</Button>
                        </Link>
                        <br />
                        <Link to="/admin">
                            <Button>Admin Page</Button>
                        </Link>
                        <br/>
                    </div>
                )}

            </GreyBox>
        </div>
    )
}

export default AdminEditResearchCall;