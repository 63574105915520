import { Navigate } from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../AxiosInstance/axios";

export const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('access_token'))

  useEffect(()=>{
      checkLogin()
  })

  async function checkLogin(){
        await axiosInstance.get(
            '/api/checkLogin/',
        ).then(response => {
            if (!(response.status >= 200 && response.status < 300)) {
                setUser(null)
                localStorage.clear()
                window.location.href="/login/"
            }
        }).catch(err=>{
            setUser(null)
            localStorage.clear()
            window.location.href="/login/"
        });
    }

  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};