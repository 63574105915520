import React, { useState, useEffect } from 'react';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import {Button, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminAllResearchCalls = () => {

    const [proposals, setProposals] = useState([])
    const [selectedRow, setSelectedRow] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const getMatchmakerProposals = () => {
        axiosInstance.get("api/register/details/GetCalls")
        .then((res) => {
          setProposals(res.data.calls)
        })
      }

    useEffect(() => {
        getMatchmakerProposals()
    }, [])

    const handleDelete = (row) => {
        setSelectedRow(row);
        setConfirmOpen(true);
    }

    const handleConfirmDelete = () => {
        const data = {title: selectedRow.title}
        axiosInstance.post('/api/delete_match_maker_call', data, {
            headers:
                {'Content-Type': 'application/json'},
        }).then((res) => {
            if (res.data.status === 'success') {
                // After deleting from the database, remove the row from the state
                setProposals(proposals.filter(proposal => proposal.title !== selectedRow.title));
            }
        })
        setConfirmOpen(false);  // Close the confirmation dialog
    }

    let columns = [
        {
            name: 'Title',
            selector: row=> row.title,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row=> row.funding_amount,
            sortable: true,
        },
        {
            name: 'Closing Date',
            selector: row=> formatClosingDate(row.closing_date),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Link to={`/admin/currentResearchCalls/${row.title}`}><Button variant="contained" color="primary" style={{marginRight: '10px'}}>view call</Button></Link>
                    <Link to={`/admin/editResearchCall/${row.title}`}><Button variant="contained" color="secondary" style={{marginRight: '10px'}}>EDIT</Button></Link>
                    <Button variant="contained" style={{backgroundColor: 'red', color: 'white'}} onClick={() => handleDelete(row)}>Delete</Button>
                </>
            )
        }
    ]

    const customStyles = {
        rows: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
            },
        },
        cells: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
            },
        },
        headCells: {
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
    };

    function formatClosingDate(date) {
        let formattedDate = ""
        let splitDate = date.split("-")
        formattedDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0]
        return formattedDate
    }

    return (
        <div>
            <GreyBox>
                <br />
                <Item sx={{textAlign: "center"}}><h1>Current Research Calls:</h1></Item>
                <div style={{ padding: '0 5vw'}}>
                    <DataTable
                        columns={columns}
                        data={proposals}
                        customStyles={customStyles}
                    />
                </div>

                <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                    <DialogTitle style={{color: 'black', fontWeight: 'bold'}}>Confirm Delete</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this call?
                        <br />
                        <br />
                        This cannot be reversed!
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
                        <Button style={{color: 'red'}} onClick={handleConfirmDelete}>Delete</Button>
                    </DialogActions>
                </Dialog>

                <Link to="/admin/registerResearchCall" style={{textDecoration: 'none'}}>
                    <div style={{alignItems: 'center'}}>
                        <p style={{fontWeight: 'bold', fontSize: "20px", color:'black'}}>Want to Create a Research Call?</p>
                    </div>
                </Link>

                <br />
            </GreyBox>
        </div>
    )
}

export default AdminAllResearchCalls;