import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axiosInstance from "../../AxiosInstance/axios";
import DoneIcon from '@mui/icons-material/Done';


const RegistrationConfirmed = ({  }) => {

  const { uidb64, activationCode } = useParams();
  
  const [success, setSuccess] = useState("Waiting...")

  useEffect(() => {
    axiosInstance.get(`api/activate/${uidb64}/${activationCode}/`)
    .then((res) => {
      if(res.status === 200) {
        console.log(res)
        console.log(`activate/${uidb64}/${activationCode}`)
        setSuccess("Your account has been activated! You may now log in.")
      } else {
        setSuccess("There has been an error.")
      }
    })
    .catch((err) => {
      console.log(err)
      setSuccess("There has been an error on our end. Please contact an administrator.")
    })
  }, [uidb64, activationCode])

  return (
    <div>
      <h1>{success}</h1>
      <br />
      <DoneIcon sx={{ fontSize: "150px", marginBottom: "30px" }} />
      <br />
      <br />
    </div>
  )
} 

export default RegistrationConfirmed