import axios from 'axios'
import DEFAULT_VARS from "../util/Config"

const axiosInstance = axios.create({
    timeout: 10000,
		baseURL: DEFAULT_VARS.BASE_URL,
    headers: {
        Authorization: localStorage.getItem('access_token')
            ? 'Bearer ' + localStorage.getItem('access_token')
            : null,
        'Content-Type': 'application/json',
        accept: 'application/json'
    }
})

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		if (typeof error.response === 'undefined') {
			console.log(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url ===  '/api/token/refresh/'
		) {
			window.location.href = '/login/';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');
			if (refreshToken) {
				return axiosInstance
					.post('/api/token/refresh/', { refresh: refreshToken })
					.then((response) => {
						localStorage.setItem('access_token', response.data.access);
						localStorage.setItem('refresh_token', response.data.refresh);
						axiosInstance.defaults.headers['Authorization'] =
							'Bearer ' + response.data.access;
						originalRequest.headers['Authorization'] =
							'Bearer ' + response.data.access;

						return axiosInstance(originalRequest);
					})
					.catch((err) => {
						console.log(err);
						localStorage.clear()
						window.location.href = '/login/';
					});
			} else{
				localStorage.clear()
				window.location.href = '/login/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance
