import './App.css';
import MatchmakerAppBar from "./Navbar/Navbar";
import Footer from "./Pages/Footer";
import Router from "./Router";
import axiosInstance from "./AxiosInstance/axios";
import {useEffect, useState} from "react";

function App() {

    const [isLoggedIn, setLoggedIn] = useState(false)
    const [isAdmin, setAdmin] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('access_token') === null) {
            console.log("Access token does not exist")
            setLoggedIn(false)
            setAdmin(false)
        } else {
            console.log("Access token exists")
            try {
                checkLogin()
                checkAdmin()
            } catch (e) {
                console.log("Error in trying to check login and admin status. Setting login as false and clearing localstorage.")
                console.log(e)
                setLoggedIn(false)
                localStorage.clear()
            }
        }

    }, []);

    async function checkLogin(){
        await axiosInstance.get(
            '/api/checkLogin/',
        ).then(response => {
            if (response.status >= 200 && response.status < 300) {
                setLoggedIn(true)
            } else {
                setLoggedIn(false)
            }
        }).catch(err=>{
            setLoggedIn(false)
            console.log("Error in checkLogin. Clearing localstorage.")
            console.log(err)
            localStorage.clear()
        });
    }

    async function checkAdmin() {
        await axiosInstance.get("api/register/checkAdmin")
            .then((res) => {
                setAdmin(res.data.admin);
                localStorage.setItem("admin", res.data.admin)
            })
            .catch((err) => {
                setAdmin(false);
                console.log("Error in checkAdmin. Clearing localstorage.")
                console.log(err)
                localStorage.clear()
            });
    }

    return (
        <div className="App">
            <MatchmakerAppBar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} isAdmin={isAdmin} setAdmin={setAdmin}/>
            <Router isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn}/>
            <Footer isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
        </div>
    );
}

export default App;
