import { Navigate } from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../AxiosInstance/axios";

export const AdminProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('access_token'))
  const [admin, setAdmin] = useState(null)
  const [loading, setLoading] = useState(true);

  async function checkLogin(){
    await axiosInstance.get(
        '/api/checkLogin/',
    ).then(response => {
        if (!(response.status >= 200 && response.status < 300)) {
            setUser(null)
            localStorage.clear()
            window.location.href="/login/"
        }
    }).catch(err=>{
        setUser(null)
        localStorage.clear()
        window.location.href="/login/"
    });
  }

  const checkAdmin = () => {
    axiosInstance.get("api/register/checkAdmin")
      .then((res) => {
        setAdmin(res.data.admin);
        localStorage.setItem("admin", res.data.admin)
      })
      .catch((err) => {
        setAdmin(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    checkLogin()
    checkAdmin()
  }, [])

  useEffect(() => {
    setLoading(false); 
  }, [admin])

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || admin === false) {
    return <Navigate to="/" />;
  }

  return children;
  
};