import React, {useState} from "react";
import {Box, Button, Card, Stack, TextField, Zoom, Paper} from "@mui/material";
import {Link} from "react-router-dom";
import axios from "axios";
import "./Login.css";
import { styled } from '@mui/material/styles';
import axiosInstance from "../AxiosInstance/axios";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isFailedLogin, setFailedLogin] = useState(false)


    const submit = async e => {
        e.preventDefault();
        const user = {
            email: username.toLowerCase(),
            password: password
        };

        // Create the POST requuest
        await axiosInstance.post('/api/token/',
            user, {
                headers:
                    {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response.status !== 200) {
                    setFailedLogin(true)
                } else {
                    let data = response.data
                    // Initialize the access & refresh token in localstorage.
                    localStorage.clear();
                    localStorage.setItem('access_token', data.access);
                    localStorage.setItem('refresh_token', data.refresh);
                    axios.defaults.headers.common['Authorization'] =
                        `Bearer ${data['access']}`;
                    window.location.href = '/userdashboard'
                    setFailedLogin(false)
                }
            })
            .catch((error) => {
                setFailedLogin(true)
            });
    }

    return (
        <Zoom in={true}>
                <Box sx={{padding: "90px", backgroundColor: "#F5F5F5"}}>
                    <Card variant="outlined" className="login-form" justify="center">
                        <div>
                            <h2 style={{marginBottom: "50px"}}>Login</h2>
                            <div>
                                <form onSubmit={submit} className="text-fields">
                                    <Stack spacing={{xs: 1}}>
                                        <Item>Email*</Item>
                                        <TextField required type={"text"} style={{marginBottom: "25px"}}
                                                onChange={e => setUsername(e.target.value)}></TextField>
                                        <Item>Password*</Item>
                                        <TextField required type={"password"}
                                                onChange={e => setPassword(e.target.value)}></TextField> <br/>
                                        <div style={{textAlign: "center"}}>
                                            <Button variant={"outlined"} sx={{color: "#333333", fontWeight: "400", width: "30%", textTransform: "none",}} 
                                            type="submit">Log in</Button>
                                        </div>

                                    </Stack>
                                </form>
                            </div>
                            {isFailedLogin ? <h2 style={{fontWeight: "normal"}}>Incorrect email or password. If the issue persist please contact <a href="https://www.research-innovation-scotland.co.uk/contact-us/">Ris-Partnerships</a></h2> : <></>}
                            <div style={{marginTop: "25px"}}>
                                <Link to={"/forgotPassword"}>Forgot your password?</Link>
                            </div>
                            <div style={{marginTop: "25px"}}>
                                <Link to={"/register"}>No account? No problems, just register!</Link>
                            </div>
                        </div>
                    </Card>
                </Box>
        </Zoom>

    );
}

export default Login;