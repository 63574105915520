import {Alert, AlertTitle, Autocomplete, Card, CardContent, Divider, LinearProgress, Paper, Stack} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import axiosInstance from "../AxiosInstance/axios";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CoffeeIcon from '@mui/icons-material/Coffee';
import GreyBox from "../Components/Common/GreyBox";
import { styled } from '@mui/material/styles';
import Cb1Img from '../Images/coffeebreak/cb1.png';
import "./CoffeeBreakSignup.css";
import {Link} from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CoffeeBreakSignUp = () => {
    const [matchingState, setMatchingState] = useState(0) //this is to render the correct page: 0 fill in details, 1 loading screen, 2 match found, 3 match not found, 4 error
    const [matchDetails, setMatchDetails] = useState({})
    const [countries, setCountries] = useState([])
    const [professions, setProfessions] = useState([])
    const [formDetails, setFormDetails] = useState({})

    const [formErrors, setFormErrors] = useState([])

    useEffect(()=>{
        fillCountries()
        fillResearchPools()
    }, [])



    function fillCountries(){
        axiosInstance.get("/api/details/getCountries")
      .then(response => {
        // Handle the response data here
        setCountries(response.data["countries"].map((country) => {
                        return { title: country };
                        }
                        )
                    )
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error);
        alert("An error has occurred, please refresh the page. If this persist please contact the team and/or try again later.")
      });
    }

    function fillResearchPools(){
        axiosInstance.get("/api/details/getProfessions")
      .then(response => {
        // Handle the response data here
        setProfessions(response.data["professions"].sort((a, b) => (a.label > b.label) ? 1 : -1))
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error);
        alert("An error has occurred, please refresh the page. If this persist please contact the team and/or try again later.")
      });
    }

    function validateForm(){
        let errors = []
        if(!formDetails.countries || formDetails.countries.length === 0){
            errors.push("You must select at least one country to proceed")
        }
        if(!formDetails.professions || formDetails.professions.length === 0){
            errors.push("You must select a discipline")
        }
        setFormErrors(errors)
        return errors.length===0
    }

    function createCoffeeBreak(){
        let isValid = validateForm()
        if(isValid){
            setMatchingState(1)
            axiosInstance.post("/api/coffeeBreak/createNew", formDetails, {
                headers:
                    {'Content-Type': 'application/json'},
            }).then((response)=>{
                if(response.status===204)//empty response aka no match found
                    setMatchingState(3)
                else{
                    setMatchingState(2)
                    setMatchDetails(response.data)
                }
            }).catch(error=>{
                setMatchingState(4)
                if(error.response?.data){
                    window.alert("An error has occured: "+error.response.data)
                }else{
                    window.alert("An error has occurred, please try submitting again")
                }
                console.error(error)
            })
        }
    }


    function updateFormState(key, value){
        setFormDetails({...formDetails, [key]:value})
    }

    function goBack(){
        window.location.href = "/userdashboard";
    }

    return (
        <Box sx={{ width: '100%'}} align={"center"}>
            <GreyBox sx={{ width: '100%'}}>
                <Stack direction="row" spacing={5} className="grey-background" style={{padding: "50px"}}>
                    <Item className="grey-background" style={{textAlign: "center"}}>
                        <h2>Sign up today</h2>
                            <div className="register-container2">
                            <p className="register-text">
                                <br />
                                Interested in building your multidisciplinary
                                network through short and informal online
                                conversations between fellow researchers in
                                Scotland?
                                <br />
                                <br />
                                Get involved in a Coffee Break @ RIS
                            </p>
                        </div>
                    </Item>
                    <Item className="grey-background" style={{textAlign: "left", marginLeft: "0px"}}><img src={Cb1Img} className="Intro_Image" height={"300px"} width={"450px"} alt="Intro_Image"/></Item>
                </Stack>
            
                {matchingState===0 &&
                <div>
                    <Item >
                        <h2 style={{textAlign:"center"}}>CoffeeBreak Application Form</h2>
                    </Item>
                    <Stack spacing={2}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p style={{width: "50%"}}>
                                Research and Innovation Scotland (RIS) Coffee Break Meetups is a rolling initiative to
                                enable connections between researchers for 15 minutes chats.
                                <br />
                                <br />
                                This form will take approximately 2 minutes to complete. The more options you select, the quicker you will get a match.
                            </p>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <GreyBox sx={{ width: '100%'}}>
                                {formErrors.length>0 &&
                                    <Alert severity="error" style={{ textAlign: 'left' }}>
                                        <AlertTitle>Errors occurred while submitting</AlertTitle>
                                        Errors have occured while submitting, please correct them: <br/>
                                        <ul>
                                            {formErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                }
                            </GreyBox>
                        </div>
                        <div className="dropdown">
                            <Stack spacing={1}>
                                <div style={{marginBottom: "10px"}}>
                                    Which countries would you like to engage with?
                                </div>
                                <Autocomplete
                                  multiple
                                  id="checkboxes-tags-countries"
                                  options={countries}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.title}
                                  isOptionEqualToValue={(option, value) => option.title === value.title}
                                  onChange={(event, newValue)=>{updateFormState("countries", newValue.map(curCountry => curCountry.title))}}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.title}
                                    </li>
                                  )}
                                  style={{ width: 500, margin: "auto", marginBottom: "30px" }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Select countries" placeholder="Select countries" />
                                  )}
                                />
                            </Stack>
                        </div>
                        <div className="dropdown">
                            <Stack spacing={1}>
                                <div style={{marginBottom: "10px"}}>
                                    Which discipline would you like to speak to?
                                </div>
                                <Autocomplete
                                  multiple
                                  id="checkboxes-tags-countries"
                                  options={professions}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.label}
                                  isOptionEqualToValue={(option, value) => option.label === value.label}
                                  onChange={(event, newValue)=>{updateFormState("professions", newValue.map(curProfession => curProfession.label))}}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.label}
                                    </li>
                                  )}
                                  style={{ width: 500, margin: "auto" }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Select disciplines" placeholder="Select disciplines" />
                                  )}
                                />
                            </Stack>
                        </div>
                        <div>
                            <Box sx={{ width: '100%'}} align={"center"}>
                                <Stack direction="row" spacing={2} style={{display: "flex", justifyContent: "center"}} divider={<Divider orientation="vertical" flexItem />}>
                                    <Button
                                        key={"go-back"}
                                        size="small"
                                        variant="outlined"
                                        onClick={goBack}
                                        sx={{my: 1,textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600"}}
                                    >
                                        Go back
                                    </Button>
                                    <Button
                                        key={"submit"}
                                        size="small"
                                        variant="outlined"
                                        onClick={createCoffeeBreak}
                                        sx={{my: 1,textTransform: 'none', margin: "9px !important" , padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600"}}
                                    >
                                        Apply Now
                                    </Button>
                                </Stack>
                                <div style={{margin: "20px 0px 20px 0px"}}>
                                    <a href="https://www.research-innovation-scotland.co.uk/contact-us/">If the discipline you want to meet isn't listed, get in touch!</a>
                                </div>
                            </Box>
                        </div>
                    </Stack>
                </div>
                }
                {matchingState===1 && <div>
                    <h3>Creating CoffeeBreak & Matching</h3>
                    <LinearProgress/>
                </div>}
                {matchingState===2 && <div>
                    <h3>Match created</h3>
                    <p>We have found a match for your CoffeeBreak request.</p>
                    <p>The contact details to your match are below:</p>
                    <Card style={{maxWidth:"450px"}}>
                        <CardContent>
                            <p>{matchDetails.name}<br/>
                            ({matchDetails.workplace}, {matchDetails.country})
                            </p>
                            <ul style={{textAlign:"left"}}>
                                <li>E-mail: {matchDetails.email}</li>
                                {matchDetails.linkedIn && <li>LinkedIn: <a href={matchDetails.linkedIn} target={"_blank"}>{matchDetails.linkedIn}</a></li>}
                            </ul>
                            <p>CoffeeBreak topics: {matchDetails.participantProfession}/{matchDetails.authorProfession}</p>
                        </CardContent>
                    </Card>
                    <p>The above details will show up on your CoffeeBreak dashboard and we also sent an e-mail to you.</p>
                    <Button
                        key={"go-back"}
                        size="small"
                        variant="outlined"
                        onClick={goBack}
                        sx={{my: 1,textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600"}}
                    >
                        Go back
                    </Button>
                </div>}
                {matchingState===3 && <div>
                    <h3>Sorry, no match found just now</h3>
                    <p>We are sorry, we could not immediately find the perfect match for you. However, do not worry, we will keep your request and once we find a matching person we will notify you via e-mail.</p>
                    <p>You can also track your CoffeeBreaks via the dashboard, this one will be put under the pending ones.</p>
                    <Button
                        key={"go-back"}
                        size="small"
                        variant="outlined"
                        onClick={goBack}
                        sx={{my: 1,textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600"}}
                    >
                        Go back
                    </Button>
                </div>}
                {matchingState===4 && <div>
                    <h3>An error has occurred</h3>
                    <p>We are sorry, while submitting an error has occurred on our side. If you cannot see your CoffeeBreak either on under Upcoming or Pending meetings, please submit your request again.</p>
                </div>}
            </GreyBox>
        </Box>
    )
}

export default CoffeeBreakSignUp