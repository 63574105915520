import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import axiosInstance from "../../AxiosInstance/axios";
import DoneIcon from '@mui/icons-material/Done';
import {Button, TextField, styled, Paper, Stack,Card } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const PasswordChanged = () => {

    const { uidb64, activationCode } = useParams();
    const [successMessage, setSuccessMessage] = useState("Waiting...")
    const [success, setSuccess] = useState(0)
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSuccess(0)
        if (newPassword !== confirmPassword) {
            setPasswordError(true)
        } 
        else {
            setPasswordError(false)
            try {
                await axiosInstance.post(`api/reset/${uidb64}/${activationCode}/`, 
                    {confirmPassword: confirmPassword},
                    {headers: {'Content-Type': 'application/json'},
                }).then(response => {
                    if (response.status !== 200) {
                        alert("An error occurredHERE.")
                    } else {
                    console.log(response)
                    }
                })
                setSuccessMessage("Password reset successful. Please log in.")
                setSuccess(1)
            } catch (error) {
                console.log(error)
                setSuccess(-1)
                setSuccessMessage("An error occurred. " + error.response.data.error)
            }
        }
    }

    return (
        <div>

            {success !== 1 && (
                <div> 
                    <h1>Password Reset</h1>

                    {success === -1 && (
                        <h2 style={{color:"red"}}>{successMessage + ". Try re-sending email again"}</h2>
                    )}

                    <form onSubmit={handleSubmit}>

                        <Card variant="outlined" className="register" sx={{maxWidth: "1000px", margin: "auto", border: "none !important"}}> 
                            <Stack spacing={{xs: 1}}>

                                <Item>Enter New Password:</Item>
                                <TextField 
                                    error={passwordError}
                                    helperText={passwordError ? "Passwords do not match" : ""}
                                    required 
                                    fullWidth
                                    type={"password"} 
                                    style={{marginBottom: "25px"}}
                                    onChange={e => setNewPassword(e.target.value)}>
                                </TextField>

                                <Item>Confirm New Password:</Item>
                                <TextField 
                                    error={passwordError}
                                    helperText={passwordError ? "Passwords do not match" : ""}
                                    required
                                    fullWidth
                                    type={"password"}
                                    style={{marginBottom: "25px"}}
                                    onChange={e => setConfirmPassword(e.target.value)}>
                                </TextField>

                                <div style={{textAlign: "center"}}>
                                    <Button variant={"outlined"} sx={{color: "#333333", fontWeight: "400", width: "40%", textTransform: "none",}} style={{marginBottom: "25px"}}
                                    type="submit">Submit</Button>
                                </div>

                                <a href="/forgotPassword"><Button variant="outlined" color="primary" sx={{color: "#333333", fontWeight: "400", width: "40%", textTransform: "none",}} style={{marginBottom: "15px"}}>Re-Send Email?</Button></a>

                            </Stack>
                        </Card>
                    </form>
                </div>
            )}

            {success === 1 && (
                <div>
                    <h1>{successMessage}</h1>
                    <br />
                    <DoneIcon sx={{ fontSize: "150px", marginBottom: "30px" }} />
                    <br />
                    <br />
                </div>
            )}

        </div>
    )
} 

export default PasswordChanged
