import React, { useEffect, useState } from 'react';
import axiosInstance from '../../AxiosInstance/axios';
import GreyBox from '../Common/GreyBox';
import { Paper, Button, TextField, Chip } from "@mui/material";
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { CSVLink } from "react-csv";
import DataTable from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Tooltip from '@mui/material/Tooltip';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminCurrentResearchCall = () => {

    const { title } = useParams();

    const [call, setCall] = useState([])
    const [experts, setExperts] = useState([])
    const [proposals, setProposals] = useState([])
    const [csvProposals, setCSVProposals] = useState([])
    const [csvExperts, setCSVExperts] = useState([])

    // Details Dialogs for proposals and experts
    const [openProposal, setProposalOpen] = useState(false);
    const [selectedProposalRow, setSelectedProposalRow] = useState(null);
    const [openExpert, setExpertOpen] = useState(false);
    const [selectedExpertRow, setSelectedExpertRow] = useState(null);

    // Note Dialogs for proposals and experts
    const [proposalNoteOpen, setProposalNoteOpen] = useState(false);
    const [selectedProposalRowNote, setSelectedProposalRowNote] = useState(null);
    const [proposalNote, setProposalNote] = useState("")
    const [expertNoteOpen, setExpertNoteOpen] = useState(false);
    const [selectedExpertRowNote, setSelectedExpertRowNote] = useState(null);
    const [expertNote, setExpertNote] = useState("")

    const getMatchmakerCall = () => {
        axiosInstance.get("api/getSpecificCall/" + encodeURIComponent(title))
        .then((res) => {
            //console.log(res.data)
            setCall(res.data)
        })
    }

    // Get the all experts and proposals, filter out based on call title, get user details based on email and merge both data into one object to be displayed
    const getMatchmakerExpertsAndProposals = () => {
        axiosInstance.get("api/register/getCallProposalsAndExpertiseRequests")
        .then((res) => {

            // Filter the proposals and experts based on the call title
            const fileterdProposals = res.data.proposals.filter(proposal => proposal.call === title)
            const filteredExperts = res.data.experts.filter(expert => expert.call === title)
        
            // Add the neccessary keys to the proposals and experts objects to be displayed
            const tempCleanedProposals = []
            const tempCleanedExperts = []

            Promise.all(fileterdProposals.map(fProposal => cleanProposalCall(fProposal)))
            .then(cleanedProposals => {
                // cleanedProposals is an array of all the resolved values
                tempCleanedProposals.push(...cleanedProposals);
                setCSVProposals(cleanCSVProposals(tempCleanedProposals))
                setProposals(tempCleanedProposals)
            });
            
            Promise.all(filteredExperts.map(fExpert => cleanExpertCall(fExpert)))
            .then(cleanedExperts => {
                // cleanedProposals is an array of all the resolved values
                tempCleanedExperts.push(...cleanedExperts);
                setCSVExperts(cleanCSVExperts(tempCleanedExperts))
                setExperts(tempCleanedExperts)
            });
            
        })
    }

    const postProposalNote = () => {
        const data ={"notes": proposalNote, "proposalTitle": selectedProposalRowNote.proposalTitle}
        axiosInstance.post("api/setProposalNote/", data, {headers: {'Content-Type': 'application/json'}})
        .then((res) => {
            getMatchmakerExpertsAndProposals()
        })
    }

    const postExpertNote = () => {
        const data ={"notes": expertNote, "description_of_competence": selectedExpertRowNote.descOfCompetence}
        axiosInstance.post("api/setExpertNote/", data, {headers: {'Content-Type': 'application/json'}})
        .then((res) => {
            getMatchmakerExpertsAndProposals()
        })
    }

    const getUserDetails = (userEmail) => {
        return axiosInstance.get("api/generic/userDetails/" + userEmail)
        .then((res) => {
            return res.data
        })
    }

    const cleanProposalCall = async (proposal) => {
        const data = {}
        const userDetails = {}

        userDetails.userDet = await getUserDetails(proposal.user)

        data.name = userDetails.userDet.firstName + " " + userDetails.userDet.lastName
        data.proposalTitle = proposal.proposalTitle
        data.principle_investigator = proposal.principle_investigator
        data.institution = userDetails.userDet.workplace
        data.expertise = proposal.expertise.join(', ')
        data.description = proposal.description
        data.country = userDetails.userDet.country
        data.email = userDetails.userDet.email
        data.linkedIn = userDetails.userDet.linkedIn
        data.profession = userDetails.userDet.profession
        data.notes = proposal.notes

        return data
    }

    const cleanExpertCall = async (expert) => {
        const data = {}
        const userDetails = {}

        userDetails.userDet = await getUserDetails(expert.user)

        data.name = userDetails.userDet.firstName + " " + userDetails.userDet.lastName
        data.institution = userDetails.userDet.workplace
        data.pools = userDetails.userDet.researchPool
        data.keywordsFromProfile = userDetails.userDet.interests.join(', ')
        data.descOfCompetence = expert.description_of_competence
        data.descOfUsefulness = expert.description_of_usefulness
        data.country = userDetails.userDet.country
        data.email = userDetails.userDet.email
        data.linkedIn = userDetails.userDet.linkedIn
        data.profession = userDetails.userDet.profession
        data.notes = expert.notes

        return data
    }

    const cleanCSVProposals = (tempProposals) => {

        const tempCSVProposalsArray = []

        for (let i = 0; i < tempProposals.length; i++) {
            const tempCSVProposals = {
                "name": tempProposals[i].name,
                "proposalTitle": tempProposals[i].proposalTitle,
                "principle_investigator": tempProposals[i].principle_investigator === true ? "Yes" : "No",
                "institution": tempProposals[i].institution,
                "expertise": tempProposals[i].expertise,
                "description": tempProposals[i].description,
                "country": tempProposals[i].country,
                "email": tempProposals[i].email,
                "linkedIn": tempProposals[i].linkedIn === "" ? "N/A" : tempProposals[i].linkedIn,
                "profession": tempProposals[i].profession,
            }
            tempCSVProposalsArray.push(tempCSVProposals)
        }

        return tempCSVProposalsArray
    }

    const cleanCSVExperts = (tempExperts) => {
        const tempCSVExpertsArray = []

        for (let i = 0; i < tempExperts.length; i++) {
            const tempCSVExperts = {
                "name": tempExperts[i].name,
                "institution": tempExperts[i].institution,
                "pools": tempExperts[i].pools,
                "keywordsFromProfile": tempExperts[i].keywordsFromProfile,
                "descOfCompetence": tempExperts[i].descOfCompetence,
                "descOfUsefulness": tempExperts[i].descOfUsefulness,
                "country": tempExperts[i].country,
                "email": tempExperts[i].email,
                "linkedIn": tempExperts[i].linkedIn === "" ? "N/A" : tempExperts[i].linkedIn,
                "profession": tempExperts[i].profession,
            }
            tempCSVExpertsArray.push(tempCSVExperts)
        }
        
        return tempCSVExpertsArray
    }

    let proposalsTableColumns = [
        {
            name: 'Name',
            selector: row=> row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'PI',
            selector: row=> row.principle_investigator=== true ? 'Yes' : 'No',
            sortable: true,
            maxWidth: '70px',
        },
        {
            name: 'Institution',
            selector: row=> row.institution,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Title',
            selector: row=> row.proposalTitle,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Expertise Sought',
            selector: row=> row.expertise,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Discipline',
            selector: row=> row.profession,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Notes',
            selector: row=> row.notes,
            sortable: true,
            wrap: true,
            grow: 2,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={() => openProposalNoteBtn(row)}>{row.notes === "" ? "add" : "edit"} note</Button>
                    <Button variant="contained" color="primary" onClick={()=> handleProposalClickOpen(row)}>Details</Button>
                </>
            )
        }
    ]

    let expertsTableColumns = [
        {
            name: 'Name',
            selector: row=> row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Institution',
            selector: row=> row.institution,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Pools',
            selector: row=> row.pools,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Keywords from Profile',
            selector: row=> row.keywordsFromProfile,
            sortable: true,
            grow: 2,
            wrap: true,
            cell: row => (
                <>
                    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                        {row.keywordsFromProfile.split(',').map((keyword, index) => (
                            <Tooltip key={index} title={keyword.trim()}> 
                                <Chip key={index} label={keyword.trim()} style={{margin: '2px'}} />
                            </Tooltip>
                        ))}
                    </div>
                </>
            ),
        },
        {
            name: 'Notes',
            selector: row=> row.notes,
            sortable: true,
            wrap: true,
            grow: 2,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={() => openExpertNoteBtn(row)}>{row.notes === "" ? "add" : "edit"} note</Button>
                    <Button variant="contained" color="primary" onClick={()=> handleExpertClickOpen(row)}>details</Button>
                </>
            )
        }
    ]

    const customTableStyles = {
        rows: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
        cells: {
            style: {
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
    };

    const callInfoStyle = {
        margin: '0', 
        paddingLeft: '10px',
    }

    // CSV Headers
    let expertCSVHeaders = [
        { label: "Name", key: "name" },
        { label: "Institution", key: "institution" },
        { label: "Pools", key: "pools" },
        { label: "Keywords From Profile", key: "keywordsFromProfile" },
        { label: "Description of Competence", key: "descOfCompetence" },
        { label: "Description of Usefulness", key: "descOfUsefulness" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "LinkedIn", key: "linkedIn" },
        { label: "Profession", key: "profession" },
    ]

    let proposalCSVHeaders = [
        { label: "Name", key: "name" },
        { label: "Proposal Title", key: "proposalTitle" },
        { label: "PI", key: "principle_investigator" },
        { label: "Institution", key: "institution" },
        { label: "Expertise Sought", key: "expertise" },
        { label: "Description", key: "description" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "LinkedIn", key: "linkedIn" },
        { label: "Profession", key: "profession" },
    ]

    //Dialog Functions
    const handleProposalClickOpen = (row) => {
        setSelectedProposalRow(row);
        setProposalOpen(true);
    };

    const handleProposalClose = () => {
        setProposalOpen(false);
    };

    const handleExpertClickOpen = (row) => {
        setSelectedExpertRow(row);
        setExpertOpen(true);
    };

    const handleExpertClose = () => {
        setExpertOpen(false);
    };

    const openProposalNoteBtn = (row) => {
        setSelectedProposalRowNote(row);
        setProposalNote(row.notes);
        setProposalNoteOpen(true);
    }

    const closeProposalNoteBtn = () => {
        setProposalNote("")
        setProposalNoteOpen(false);
    }

    const openExpertNoteBtn = (row) => {
        setSelectedExpertRowNote(row);
        setExpertNote(row.notes);
        setExpertNoteOpen(true);
    }

    const closeExpertNoteBtn = () => {
        setExpertNote("")
        setExpertNoteOpen(false);
    }

    useEffect(() => {
        getMatchmakerCall()
        getMatchmakerExpertsAndProposals()
    }, [])

    function formatClosingDate(date) {
        if (date === undefined) return ""
        let formattedDate = ""
        let splitDate = date.split("-")
        formattedDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0]
        return formattedDate
    }

    return (
        <div>
            <GreyBox>
                <br/>
                
                <Item><h2 style={callInfoStyle}>Call: {call.title}</h2></Item>
                <Item><h2 style={callInfoStyle}>Created By (Email): {call.created_by_email}</h2></Item>
                <Item><h2 style={callInfoStyle}>Created By (First Name): {call.created_by_fName}</h2></Item>
                <Item><h2 style={callInfoStyle}>Created By (Last Name): {call.created_by_lName}</h2></Item>
                <Item><h2 style={callInfoStyle}>Amount: {call.funding_amount}</h2></Item>
                <Item><h2 style={callInfoStyle}>Closing Date: {formatClosingDate(call.closing_date)}</h2></Item>
                <Item><h2 style={callInfoStyle}>Link: {call.link === "" ? "N/A" : <a href={/^http/.test(call.link) ? call.link : `http://${call.link}`} target="_blank" rel="noopener noreferrer">{call.link}</a>}</h2></Item>
                <Item><h2 style={callInfoStyle}>Expertise: {call.expertise === "" ? "N/A" : call.expertise}</h2></Item>
                <Item><h2 style={callInfoStyle}>Funder: {call.funder === "" ? "N/A" : call.funder}</h2></Item>
                <Item><h2 style={callInfoStyle}>Description: {call.description === "" ? "N/A" : call.description}</h2></Item>

                {proposals.length != 0 && (
                    <div>
                        <br/>
                        <CSVLink 
                            data={csvProposals} 
                            filename={"Proposals of " + call.title + ".csv"} 
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                textDecoration: 'none',
                                outline: 'none',
                                color: '#fff',
                                backgroundColor: '#4CAF50',
                                border: 'none',
                                borderRadius: '15px',
                                boxShadow: '0 9px #999'
                            }}
                            headers={proposalCSVHeaders}
                            target='_blank'
                        >
                            Download Proposal CSV
                        </CSVLink>
                    </div>
                )}

                <Item><h2 style={{paddingLeft:"10px"}}>Proposals:</h2></Item>
                <div style={{ padding: '0 5vw'}}>
                    <DataTable
                        columns={proposalsTableColumns}
                        data={proposals}
                        noHeader
                        customStyles={customTableStyles}
                    />
                </div>

                <Dialog open={openProposal} onClose={handleProposalClose}>
                    <DialogTitle>Details</DialogTitle>
                    <DialogContent>
                        <Item><h1>Proposal Title: {selectedProposalRow?.proposalTitle}</h1></Item>
                        <Item><h1>Notes: {selectedProposalRow?.notes === "" ? "N/A" : selectedProposalRow?.notes}</h1></Item>
                        <Item><h1>Description: {selectedProposalRow?.description}</h1></Item>  
                        <Item><h1>Country: {selectedProposalRow?.country}</h1></Item>
                        <Item><h1>Email: {selectedProposalRow?.email}</h1></Item>
                        <Item><h1>LinkedIn: {selectedProposalRow?.linkedIn === "" ? "N/A" : selectedProposalRow?.linkedIn}</h1></Item>
                        <Item><h1>Profession: {selectedProposalRow?.profession}</h1></Item>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleProposalClose}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={proposalNoteOpen} onClose={closeProposalNoteBtn} maxWidth="xl" fullWidth>
                    <DialogTitle>Add Note</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To add a note, please enter your note here.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="note"
                            label="Note"
                            type="text"
                            fullWidth
                            multiline
                            minRows={5}
                            value={proposalNote}
                            onChange={e=> setProposalNote(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeProposalNoteBtn}>Cancel</Button>
                        <Button onClick={() => {postProposalNote(); closeProposalNoteBtn()}} >Save</Button>
                    </DialogActions>
                </Dialog>
                
                {experts.length != 0 && (
                    <div>
                        <br/>
                        <CSVLink 
                            data={csvExperts} 
                            filename={"Experts of " + call.title + ".csv"} 
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                textDecoration: 'none',
                                outline: 'none',
                                color: '#fff',
                                backgroundColor: '#4CAF50',
                                border: 'none',
                                borderRadius: '15px',
                                boxShadow: '0 9px #999'
                            }} 
                            headers={expertCSVHeaders}
                            target='_blank'
                        >
                            Download Experts CSV
                        </CSVLink>
                    </div>
                )}
                
                
                <Item><h2 style={{paddingLeft:"10px"}}>Experts:</h2></Item>
                <div style={{ padding: '0 5vw'}}>
                    <DataTable
                        columns={expertsTableColumns}
                        data={experts}
                        customStyles={customTableStyles}
                    />
                </div>

                <Dialog open={openExpert} onClose={handleExpertClose}>
                    <DialogTitle>Details</DialogTitle>
                    <DialogContent>
                        <Item><h1>Notes: {selectedExpertRow?.notes === "" ? "N/A" : selectedExpertRow?.notes}</h1></Item>
                        <Item><h1>Description of Comptence: {selectedExpertRow?.descOfCompetence}</h1></Item>
                        <Item><h1>Description of Usefulness: {selectedExpertRow?.descOfUsefulness}</h1></Item>
                        <Item><h1>Keywords From Profile: {selectedExpertRow?.keywordsFromProfile}</h1></Item>
                        <Item><h1>Country: {selectedExpertRow?.country}</h1></Item>
                        <Item><h1>Email: {selectedExpertRow?.email}</h1></Item>
                        <Item><h1>LinkedIn: {selectedExpertRow?.linkedIn=== "" ? "N/A" : selectedExpertRow?.linkedIn}</h1></Item>
                        <Item><h1>Profession: {selectedExpertRow?.profession}</h1></Item>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleExpertClose}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={expertNoteOpen} onClose={closeExpertNoteBtn} maxWidth="xl" fullWidth>
                    <DialogTitle>Add Note</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To add a note, please enter your note here.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="note"
                            label="Note"
                            type="text"
                            fullWidth
                            multiline
                            minRows={5}
                            value={expertNote}
                            onChange={e=> setExpertNote(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeExpertNoteBtn}>Cancel</Button>
                        <Button onClick={() => {postExpertNote(); closeExpertNoteBtn()}} >Save</Button>
                    </DialogActions>
                </Dialog>

                <br/>
            </GreyBox>
        </div>
    )
}

export default AdminCurrentResearchCall;