import React from 'react';
import { Link } from "react-router-dom"; 
import GreyBox from "../Components/Common/GreyBox";
import currentResearchCallsImage from "../Images/admin/research-calls.jpg";
import registerResearchCallImage from "../Images/admin/new-enquires.jpg";
import proposalForCallsImage from "../Images/admin/current-matches.jpg";
import expertsForCallsImage from "../Images/admin/research-pool.jpg";

const AdminPage = () => {

  //Styling for the buttons
  const gridStyle = {
    display: 'grid',
    alignItems: 'center',
    height: 'auto',
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyContent: 'center',
    gridGap: '55px',
    marginTop: '55px',
    marginBottom: '55px',
  };

  const buttonStyle = {
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  };

  const imgStyle = {
    width: '100%',
    maxWidth: '500px',
    height: 'auto',
    maxHeight: '500px',
    '@media (max-width: 600px)': { // reduce the size on smaller screens
      maxWidth: '300px',
      maxHeight: '300px',
    },
  };

  const pStyle = {
    margin: '0',
    fontSize: '1.5em',
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold',
  };

  return (
    <div>

      <GreyBox>
        <br></br>
        <div style={gridStyle}>
          
          <Link to="/admin/currentResearchCalls" style={buttonStyle}>
            <div style={buttonStyle}>
              <img src={currentResearchCallsImage} alt="Current Research Calls" style={imgStyle} />
              <p style={pStyle}>Current Research Calls</p>
            </div>
          </Link>

          <Link to="/admin/registerResearchCall" style={buttonStyle}>
            <div style={buttonStyle}>
              <img src={registerResearchCallImage} alt="Create a Research Call" style={imgStyle} />
              <p style={pStyle}>Create a Research Call</p>
            </div>
          </Link>

          <Link to="/admin/proposalsForCalls" style={buttonStyle}>
            <div style={buttonStyle}>
              <img src={proposalForCallsImage} alt="Proposals For Calls" style={imgStyle} />
              <p style={pStyle}>Proposals For Calls</p>
            </div>
          </Link>

          <Link to="/admin/expertsForCalls" style={buttonStyle}>
            <div style={buttonStyle}>
              <img src={expertsForCallsImage} alt="Experts For Calls" style={imgStyle} />
              <p style={pStyle}>Experts For Calls</p>
            </div>
          </Link>

        </div>
        <br />
      </GreyBox>
    </div>
  )
}

export default AdminPage;