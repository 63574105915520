import Button from "@mui/material/Button";
import axiosInstance from "../../AxiosInstance/axios";
import {Card, CardActionArea, CardContent, Stack, Paper} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import {useState} from "react";
import convertIsoToLocalTime from "../../util/TimeUtil";
import GroupsIcon from '@mui/icons-material/Groups';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));


const FutureMeetingSummary = ({meetingDetails, counter, Icon, cancelCbText, callbackFn}) =>{
    function cancelMeeting(event){
        event.stopPropagation()
        if(window.confirm("Are you sure you want to cancel it?")== true){
            axiosInstance.post("/api/coffeeBreak/cancelMeeting", {"id":meetingDetails.id}, {
                headers: {
                    'Content-Type': 'application/json',
                }}).then(response=>{
                    callbackFn()
                }
                ).catch(error=>{    
                    window.alert("An error has occurred. Please try again later.")
                    console.error(error)
            })
        }
    }

    function setScheduled(event, isScheduled){
        event.stopPropagation()
        axiosInstance.post("/api/coffeeBreak/setScheduled", {"id":meetingDetails.id, "isScheduled": isScheduled}, {
                headers: {
                    'Content-Type': 'application/json',
                }}).then(response=>{
                    callbackFn()
                }
                ).catch(error=>{
                    window.alert("An error has occurred. Please try again later.")
                    console.error(error)
            })
    }

    function setHistoric(event){
        event.stopPropagation()
        axiosInstance.post("/api/coffeeBreak/setHistoric", {"id":meetingDetails.id}, {
                headers: {
                    'Content-Type': 'application/json',
                }}).then(response=>{
                    window.alert("Thank you for using our services. We would appreciate if you could leave a feedback on the meeting in the Past Coffeebreaks section!")
                    callbackFn()
                }
                ).catch(error=>{
                    window.alert("An error has occurred. Please try again later.")
                    console.error(error)
            })
    }

    return(
        <Card sx={{ width: 350 }}>
            <CardActionArea>
                <CardContent>
                    <Item>
                        <h4>Connection {counter}</h4>
                        <Icon sx={{color:"#4DB0F4", fontSize: 60}}/>
                        <p><strong>Topic:</strong> {meetingDetails.meetingAbout?<>{meetingDetails.meetingAbout}</>
                            :
                            <>{meetingDetails.professions.join('/')}</>}</p>
                        <p><strong>Countries:</strong> {meetingDetails.countries.map((country)=><span key={country}>{country}/</span>)}</p>
                        <p><strong>Accepted by:</strong> {meetingDetails.otherPersonsName} <br/> {meetingDetails.otherPersonsWorkplace} </p>
                        {meetingDetails.otherPersonsLinkedIn && <p><strong>LinkedIn:</strong> <a href={meetingDetails.otherPersonsLinkedIn}>{meetingDetails.otherPersonsLinkedIn}</a></p>}
                        {meetingDetails.otherPersonsEmail && <p><strong>E-mail:</strong> <a href={"mailto:"+meetingDetails.otherPersonsEmail}>{meetingDetails.otherPersonsEmail}</a></p>}
                        <Stack spacing={1}>
                        {meetingDetails.otherPersonsName && <Button size="small"
                            variant="outlined" color="success"
                            sx={{my: 3, width: "100%", textTransform: 'none', padding:"0px 9px !important", fontFamily: "sans-serif", fontWeight:"600", display: 'flex', marginRight: "10px"}}
                            onMouseDown={event => event.stopPropagation()} onClick={(event)=>{setHistoric(event)}}><CheckCircleOutlineIcon sx={{marginRight: "8px"}}/> <p>Set as happened</p></Button>}
                            <Button variant="outlined" color="error" sx={{my: 3, width: "100%", textTransform: 'none', padding:"0px 9px !important", fontFamily: "sans-serif", fontWeight:"600", display: 'flex', marginRight: "10px"}} onMouseDown={event => event.stopPropagation()} onClick={cancelMeeting}><CancelIcon sx={{marginRight: "8px"}}/> <p>{cancelCbText}</p></Button>
                        </Stack>
                    </Item>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default FutureMeetingSummary