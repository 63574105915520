import Box from "@mui/material/Box";

const GreyBox = ({ style, children }) => {
  const paperStyle = {
    background: `#F5F5F5`,

    ...style, // Merge additional styles passed as props
  };

  return <Box style={paperStyle}>{children}</Box>;
};

export default GreyBox