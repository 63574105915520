import * as React from 'react';
import {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {Link} from "react-router-dom";
import logo from "../Images/RIS Logo.png";
import axiosInstance from '../AxiosInstance/axios';

const loggedInSettings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const loggedOutSettings = ['Log in']
const loggedOutPages = [{name: 'Login', link: "/login"},{name: "Contact", link: 'https://www.research-innovation-scotland.co.uk/contact-us/'}]
const loggedInPages = [{name: "Home", link: '/'}, {name: "Coffee Breaks", link: '/coffeeBreaks'},{name: "Match Maker", link: '/matchmaker'}, 
                        {name: "User Dashboard", link: '/userdashboard'}, {name: "Log out", link: 'logout/'},{name: "Contact", link: 'https://www.research-innovation-scotland.co.uk/contact-us/'},]
const adminLoggedInPages = [{name: "Home", link: '/'}, {name: "Coffee Breaks", link: '/coffeeBreaks'},{name: "Match Maker", link: '/matchmaker'}, 
{name: "User Dashboard", link: '/userdashboard'},{name: "Admin", link: '/admin'}, {name: "Log out", link: 'logout/'},{name: "Contact", link: 'https://www.research-innovation-scotland.co.uk/contact-us/'},]

function ResponsiveAppBar({isLoggedIn, setLoggedIn, isAdmin, setAdmin}) {
    const [pages, setPages] = React.useState(loggedOutPages)
    const [settings, setSettings] = React.useState(loggedOutSettings)
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    useEffect(() => {
        if (isLoggedIn && isAdmin) {
            setPages(adminLoggedInPages)
            setSettings(loggedInSettings)
        } else if (isLoggedIn) {
            setPages(loggedInPages)
            setSettings(loggedInSettings)
        }else{
            setPages(loggedOutPages)
            setSettings(loggedOutSettings)
        }
    }, [isLoggedIn]);


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const loginButtonOutline = (page) => {
        if (page.name === "Login" || page.name === "Log out") {
        return (
            <>
                <Button
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    size="small"
                    variant="outlined"
                    sx={{my: 3, textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block', marginRight: "10px", marginLeft: "100px"}}
                    component={Link} to={page.link}
                >
                    {page.name}
                </Button>
            </>
        )
        } else if (page.name === "Register" || page.name === "Contact") {
            return (
                <>
                    <Button
                        key={page.name}
                        onClick={handleCloseNavMenu}
                        size="small"
                        variant="outlined"
                        sx={{my: 3, textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block', marginRight: "10px"}}
                        component={Link} to={page.link}
                    >
                        {page.name}
                    </Button>
                </>
            )        
        } else {
            return (
            <>
            <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                size="medium"
                sx={{my: 3, textTransform: 'none', color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block', marginRight: "10px"}}
                component={Link} to={page.link}
            >
                {page.name}
            </Button>
        </>
            )
        }
    }

    return (
        <AppBar position="static" style={{marginBottom: "0px", backgroundColor: "white", boxShadow: "none"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                   {/* <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/> */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            margin: "0 auto",
                        }}
                    >
                    <img src={logo} height={50} width={50} alt='logo' />
                    </Typography>
                    {/*}
                    <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    */}
                    <Box sx={{flexGrow: 0, display: {xs: 'none', margin: "0 auto", md: 'flex',}}}>
                        {pages.map((page) => (
                            loginButtonOutline(page)
                        ))}
                    </Box>
                {/*
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;