import React, {useEffect, useState} from "react";
import {
    Zoom,
    Stack,
    TextField,
    Button, FormControl, FormControlLabel, FormLabel,
    Alert, AlertTitle, LinearProgress
} from "@mui/material";
import axiosInstance from "../../AxiosInstance/axios";

const IntentRegistration = ({title}) => {
    
    const [expertise, setExpertise] = useState("");
    const [description, setDescription] = useState("");
    const [success, setSuccess] = useState(0);
    const [errors, setErrors] = useState([]);
    
    async function sendData(){
        let expertiseInput = expertise.length > 0;
        let descriptionInput = description.length > 0;
        if (expertiseInput && descriptionInput) {
            console.log(expertise)
            console.log(description)
            console.log(title)
            setSuccess(1)
        /*    const formDetails = {title: title, description: description, expertise: expertise}
            await axiosInstance.post('/api/register/', formDetails , {
                headers:
                    {'Content-Type': 'application/json'},
            }).then(response => {
                if (response.status === 200) {
                    setSuccess(2)
                } else {
                    let respData = response.response.data
                    handleError(respData)
                }
            },
            error => {
                handleError(error.response.data)
            }
        ) */
        } else {
            let tempErrors = [];
            if (!expertiseInput) {
                tempErrors.push("The Expertise description is empty!")
            }
            if (!descriptionInput){
                tempErrors.push("The details section is empty!")
            }
            setErrors(tempErrors);
        }
    }
    function handleError(respData) {
        let curErrors = []
        if (respData.email) {
            curErrors.push(respData.email)
        }
        if (respData.errors) {
            curErrors = [...curErrors, ...respData.errors]
        }
        setErrors(curErrors)
    }

    return (
        <Zoom in={true}>
            <div style={{margin: "auto", maxWidth: "700px"}}>
                {errors.length > 0 &&
                    <Alert severity="error" style={{textAlign: "left"}}>
                        <AlertTitle>Error</AlertTitle>
                        The following errors occured while trying to create the account:
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </Alert>
                }
                {success === 0 && 
                <Stack spacing={2}>
                    <FormControl sx={{alignItems:"flex-start"}}>
                        <FormLabel required id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}>Provide a short description of your expertise:</FormLabel>
                        <TextField 
                        label={"Expertise"} 
                        type={"text"} 
                        multiline
                        rows={4}
                        onChange={event =>{setExpertise(event.target.value)}} 
                        sx={{ width: "100%"}}/>
                    </FormControl>
                    <br/>
                    <FormControl sx={{alignItems:"flex-start"}}>
                        <FormLabel required id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}>Provide details of how you believe this expertise will contribute to a proposal for this call:</FormLabel>
                        <TextField 
                        label={"Description"} 
                        type={"text"}
                        multiline
                        rows={4}
                        onChange={event => {setDescription(event.target.value)}} 
                        sx={{ width: "100%"}}/>
                    </FormControl>
                    <br/>
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{my: 3, width: "100%", textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block',}}
                        onClick={e => {sendData()}}
                    >
                        Submit Interest
                    </Button>
                </Stack>}
                {/*success === 1 &&
                <div>
                    <LinearProgress/>
                </div>*/}
                {success === 1 &&
                <div>
                    <h3>Thank you for your submission. We will be in touch!</h3>
                </div>}
            </div>
        </Zoom>
    );
}

export default IntentRegistration;