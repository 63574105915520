import React from "react";
import {Stack, Button, Typography, Box} from "@mui/material";
import './Home.css';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import logo from "../Images/RIS Logo.png";
import {Link as React_Link} from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));

const Footer = (isLoggedIn) => {
    return (
        <Box>
            <Stack direction={3} className="footer-background" style={{padding: "40px 40px 10px 40px"}}>
                <Item className="footer-background">                    
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                    >
                    <img src={logo} height={70} width={70} alt='logo' />
                    </Typography>
                </Item>
                <Item className="footer-background">
                    <Stack direction="row" className="footer-background" spacing={2}> 
                        <Item className="footer-background">
                            <Button
                                key={"Home"}
                                size="medium"
                                sx={{textTransform: 'none', color: '#ffffff', fontFamily: "sans-serif", fontWeight:"600",}}
                                component={React_Link} to={"/"}
                            >
                                Home
                            </Button>    
                        </Item>
                        <Item className="footer-background">                        
                            <Button
                                key={"About Us"}
                                size="medium"
                                sx={{textTransform: 'none', color: '#ffffff', fontFamily: "sans-serif", fontWeight:"600",}}
                                component={React_Link} to={"https://www.research-innovation-scotland.co.uk/our-mission/"}
                            >
                                About Us
                            </Button>
                        </Item>
                        <Item className="footer-background">
                            <Button
                                key={"Partners"}
                                size="medium"
                                sx={{textTransform: 'none', color: '#ffffff', fontFamily: "sans-serif", fontWeight:"600",}}
                                component={React_Link} to={"https://www.research-innovation-scotland.co.uk/member-organisations/"}
                            >
                                Partners
                            </Button>                            
                        </Item>
                    </Stack>
                    <div style={{marginTop: "50px"}}>
                        <p className="text-font-weight"><a href="https://www.research-innovation-scotland.co.uk/contact-us/">Contact Us Here</a></p>
                    </div>
                </Item>
                
                <Item className="footer-background">
                   {isLoggedIn.isLoggedIn && (
                        <p className="text-font-weight">
                        <Button
                            key={"Your Account"}
                            size="medium"
                            sx={{textTransform: 'none', color: '#ffffff', fontFamily: "sans-serif", fontWeight:"600",}}
                            component={React_Link} to={"/myprofile"}
                        >
                            Your Account
                        </Button>                        
                        </p>
                   )}
                </Item>
                
            </Stack>
        </Box>
    )
}

export default Footer;