import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import {Button, Card, Stack, TextField, Box, Paper, FormControl, FormControlLabel, FormLabel, Checkbox, Alert, AlertTitle } from "@mui/material";
import { Link } from "react-router-dom"; 
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminRegisterResearchCall = () => {

    const [title, setTitle] = useState("");
    const [funder, setFunder] = useState("");
    const [fundingAmount, setFundingAmount] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const [closingDate, setclosingDate] = useState("");
    const [researchCalls, setResearchCalls] = useState([]);
   
    const [pool, setPool] = useState([])
    const [poolKeywords, setPoolKeywords] = useState([])

    const [success, setSuccess] = useState(0);
    const [respData, setRespData] = useState("");
    const [respCode, setRespCode] = useState(0);

    const [formErrors, setFormErrors] = useState([]);
    const formErrorRef = useRef(null);

    const registerProposal = async () => {
        if (validateFields()) {
            //e.preventDefault()
            const data = {
            "title": title,
            "expertise": pool,
            "funder": funder,
            "description": description,
            "link": link,
            "closing_date": closingDate,
            "pools": pool,
            "funding_amount": currencySign(fundingAmount),
            }
            await axiosInstance.post("api/registerCall/", data, {headers: {'Content-Type': 'application/json'}}, {withCredentials: true})
            .then((res) => {
                if(res.status === 200) {
                    setSuccess(1)
                } else {
                    // Report other status codes and their response
                    setRespData(res.response.data)
                    setRespCode(res.status)
                    setSuccess(-1)
                }
            }, (error) => {
                setRespData(error.response.data)
                setRespCode(error.response.status)
                setSuccess(-1)
            })
        } 
    }

    const poolsNames = {
      "SAGES": "Scottish Alliance for Geoscience, Environment & Society (SAGES)",
      "Brain Health Arc": "Brain Health ARC Alliance",
      "SCAF": "The Scottish Alliance for Food (SCAF)",
      "ETP": "Scottish Research Partnership for Energy (ETP)",
      "SRPE": "Scottish Research Partership in Engineering (SRPE)",
      "SINAPSE": "Scottish Imaging Network: A platform for Scientific Excellence (SINAPSE)",
      "MASTS": "Marine Alliance for Science and Technology for Scotland (MASTS)",
      "ScotChem": "Scottish Universities Chemistry Research Pool (ScotChem)",
      "SICSA": "Scottish Informatics and Computer Science Alliance (SICSA)",
      "SULSA": "Scottish Universities Life Sciences Alliance (SULSA)",
      "SUPA": "Scottish Universities Physics Alliance (SUPA)",
      "BE-ST": "Built Environment - Smarter Transformation (BE-ST)",
      "Quantum Technology ARC": "Quantum Technology ARC"
    };

    function getPoolKeywordValues(endpoint, setterFunction, key){
      axiosInstance.get(endpoint)
        .then(response => {
          // Handle the response data here
          setterFunction(response.data[key])
        })
        .catch(error => {
          // Handle errors here
          console.error('Error:', error);
        });
    }

    const getMatchmakerCalls = () => {
        axiosInstance.get("api/register/details/GetCalls")
        .then((res) => {
            setResearchCalls(res.data.calls)
        })
    }

    useEffect(() => {
      getPoolKeywordValues("/api/register/details/getKeywords", setPoolKeywords, "poolKeywords")
      getMatchmakerCalls()
      if (formErrors.length > 0) {
        formErrorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [formErrors])

    function doesCallExist(callTitle) {
        for (let i = 0; i < researchCalls.length; i++) {
            if (researchCalls[i].title === callTitle) {
                return true
            }
        }
    }

    function currencySign(amount) {
        let parts = amount.split('-').map(part => part.trim());
        let formattedParts = parts.map(part => {
            let cleanPart = part.replace(/[^\d.£€$]/g, '');
            let numberPart = Number(cleanPart.replace(/[£€$]/g, ''));
            let formattedPart = numberPart.toLocaleString();
            let currency = cleanPart[0].match(/[£€$]/) ? cleanPart[0] : "£";
            return currency + formattedPart;
        });
        return formattedParts.join(' - ');
    }

    function validateFields() {
        setFormErrors([])
        let errors = []

        // Input Validation Logic:

            // ensure the required fields are not empty
            if (!closingDate ) {
                errors.push("Closing date is required!")
            }

            if (!title) {
                errors.push("Title is required!")
            }
            
            if (!description) {
                errors.push("Description is required!")
            }

            if (pool.length <1) {
                errors.push("At least one research pool must be selected!")
            }

            // check if call already exists by checking if the title matches the calls title 
            if (doesCallExist(title)) {
                errors.push("Research call with the same title already exists. Please choose a different title.");
            }

            // ensure link to external website is an actual link
            let urlRegex = /^((http|https):\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,6}(\/[^\s]*)?$/i;
            if (link && !urlRegex.test(link)) {
                errors.push("Link must be a valid URL.");
            }

            let fundingAmountRegex = /^[£€$]?(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?(\s*-\s*[£€$]?(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?)?$/
            if (!fundingAmountRegex.test(fundingAmount)) {
                errors.push("Funding amount must be a number or a range of numbers.")
            }

            // ensure date is in the future
            let currentDate = new Date()
            let selectedDate = new Date(closingDate)
            if (selectedDate < currentDate) {
                errors.push("Closing date cannot be in the past!")
            }

        // If errors occurred in the fields then return false and set the errors to be displayed to user
        if (errors.length > 0) {
            setFormErrors(errors);
            return false;
        } else {
            return true;
        }
        
    }

    return (
        <div>
            <GreyBox>
                {success === 0 && (
                    <Box style={{maxWidth: "500px", margin:"auto"}}>
                        <Card variant="outlined" className="register" sx={{background: `#F5F5F5`, maxWidth: "500px", margin: "auto", border: "none !important"}}>
                           {/*  <form onSubmit={registerProposal}> */}
                            <Stack className='registerProposalForm' spacing={{xs: 1}} >
                                
                                <Stack spacing={2} >
                                    <Item sx={{textAlign: "center"}}><h1>Register a new research call</h1></Item>

                                    {formErrors.length > 0 && 
                                        <Alert ref={formErrorRef} severity="error" style={{textAlign: "left"}}> 
                                            <AlertTitle>Error</AlertTitle>
                                            The following errors occured trying to create a call:
                                            <ul>
                                                {formErrors.map((error,index) => (
                                                    <li key={index}>{error}</li>
                                                ))}
                                            </ul>
                                        </Alert>
                                    }

                                    <Item>Title of the call*</Item>
                                    <TextField 
                                        fullWidth 
                                        onChange={e => setTitle(e.target.value)} required type={"text"} 
                                        minRows={2}
                                        multiline
                                    />

                                    <Item>Funding amount* <i>(by default £ sign will be used unless alternative currency sign used)</i> </Item>
                                    <TextField fullWidth  onChange={e => setFundingAmount(e.target.value)} required type={"text"} />

                                    <Item>Description*</Item>
                                    <TextField 
                                        fullWidth  
                                        minRows={5}
                                        multiline
                                        size='medium' 
                                        onChange={e => setDescription(e.target.value)} required type={"text"} 
                                    />

                                    <Item>Who is the funder?</Item>
                                    <TextField 
                                        fullWidth 
                                        size='medium' 
                                        multiline
                                        onChange={e => setFunder(e.target.value)} required type={"text"} 
                                    />

                                    <Item>Link to call's external website</Item>
                                    <TextField fullWidth size='medium' onChange={e => setLink(e.target.value)} required type={"text"} />
                                    
                                    <Item>Closing date of the call*</Item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(newValue) => setclosingDate(dayjs(newValue).format('YYYY-MM-DD'))}
                                    />
                                    </LocalizationProvider>
                                    
                                    <FormControl style={{ alignItems: "flex-start" }}>
                                        <FormLabel>What research pools, networks or partnerships, are participating in supporting matchmaking for the call? *</FormLabel>
                                        {Object.keys(poolKeywords).map((tempPool) => (
                                            <div key={tempPool}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={pool.includes(tempPool)}
                                                            onChange={() => {
                                                                if (pool.includes(tempPool)) {
                                                                    setPool(pool.filter((value) => value !== tempPool));
                                                                } else {
                                                                    setPool([...pool, tempPool]);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    style={{textAlign: 'left'}}
                                                    label={poolsNames[tempPool]}
                                                />
                                                
                                            </div>
                                        ))}
                                    </FormControl>

                                    <Item><Button variant={"outlined"} 
                                    sx={{color: "#333333", fontWeight: "400", width: "100%", textTransform: "none",}} 
                                    onClick={() => {registerProposal()}}>Submit</Button>
                                    </Item>

                                    <Item>
                                        <Link to="/admin">
                                            <Button 
                                                variant={"outlined"}
                                                sx={{color: "#333333", fontWeight: "400", width: "100%", textTransform: "none",}}
                                            >
                                                Back
                                            </Button>
                                        </Link>
                                    </Item>
                                </Stack>
                            </Stack>
                           {/*  </form>*/}
                        </Card>
                    </Box>
                )}

                {success === -1 && (
                    <div> 
                        <h1>Error Code: {respCode}</h1>
                        <h2>Please contact us and send us the below error message, along with the error code:</h2>
                        <p>{respData}</p>
                    </div>
                )}

                {success === 1 && (
                    <div>
                        <Item sx={{textAlign: "center"}}><h1>Call has been registered successfully!</h1></Item>
                        <Button onClick={() => {setSuccess(0)}}>Create another research call</Button>
                        <br />
                        <Link to="/admin/currentResearchCalls">
                            <Button>View current research calls</Button>
                        </Link>
                        <br />
                        <Link to="/admin">
                            <Button>Admin Page</Button>
                        </Link>
                        <br/>
                    </div>
                )}

            </GreyBox>
        </div>
    )
}

export default AdminRegisterResearchCall;