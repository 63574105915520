import React, { useState, useEffect } from 'react';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import {Button, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CSVLink } from "react-csv";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminProposalsForCalls = () => {

    const [requestsProposals, setRequestsProposals] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [csvProposals, setCSVProposals] = useState([])

    const getMatchmakerRequests = () => {
        axiosInstance.get("api/register/getCallProposalsAndExpertiseRequests")
        .then((res) => {
            setRequestsProposals(res.data.proposals)

            const tempCleanedProposals = []

            Promise.all((res.data.proposals).map(fProposal => cleanProposalCall(fProposal)))
            .then((cleanedProposals) => {
                tempCleanedProposals.push(...cleanedProposals);
                setCSVProposals(cleanCSVProposals(tempCleanedProposals))
            })

        })
    }

    const cleanProposalCall = async (proposal) => {
        const data = {}
        const userDetails = {}

        userDetails.userDet = await getUserDetails(proposal.user)

        data.name = userDetails.userDet.firstName + " " + userDetails.userDet.lastName
        data.proposalTitle = proposal.proposalTitle
        data.principle_investigator = proposal.principle_investigator
        data.institution = userDetails.userDet.workplace
        data.expertise = proposal.expertise.join(', ')
        data.description = proposal.description
        data.country = userDetails.userDet.country
        data.email = userDetails.userDet.email
        data.linkedIn = userDetails.userDet.linkedIn
        data.profession = userDetails.userDet.profession
        data.notes = proposal.notes

        return data
    }

    const cleanCSVProposals = (tempProposals) => {

        const tempCSVProposalsArray = []

        for (let i = 0; i < tempProposals.length; i++) {
            const tempCSVProposals = {
                "name": tempProposals[i].name,
                "proposalTitle": tempProposals[i].proposalTitle,
                "principle_investigator": tempProposals[i].principle_investigator === true ? "Yes" : "No",
                "institution": tempProposals[i].institution,
                "expertise": tempProposals[i].expertise,
                "description": tempProposals[i].description,
                "country": tempProposals[i].country,
                "email": tempProposals[i].email,
                "linkedIn": tempProposals[i].linkedIn === "" ? "N/A" : tempProposals[i].linkedIn,
                "profession": tempProposals[i].profession,
            }
            tempCSVProposalsArray.push(tempCSVProposals)
        }

        return tempCSVProposalsArray
    }

    const getUserDetails = (userEmail) => {
        return axiosInstance.get("api/generic/userDetails/" + userEmail)
        .then((res) => {
            return res.data
        })
    }

    useEffect(() => {
        getMatchmakerRequests()
    }, [])

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let columns = [
        {
            name: 'Call Title',
            selector: row=> row.call,
            sortable: true,
        },
        {
            name: 'User',
            selector: row=> row.user,
            sortable: true,
        },
        {
            name: 'Proposal Title',
            selector: row=> row.proposalTitle,
            sortable: true,
        },
        {
            name: 'Principle Investigator?',
            selector: row=> row.principle_investigator== true ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'Details',
            cell: row => (
                <>
                    <CSVLink data={csvProposals} headers={proposalCSVHeaders} filename={"Proposals of " + row.call + ".csv"}><Button variant="contained" sx={{background:"green", marginRight: "8px"}}>csv</Button></CSVLink>
                    <Button variant="contained" color="primary" onClick={() => handleClickOpen(row)}>more</Button>
                </>
            )
        }
    ]

    const customStyles = {
        rows: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
            },
        },
        cells: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'center',
                backgroundColor: '#F5F5F5',
            },
        },
        headCells: {
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
    };

    let proposalCSVHeaders = [
        { label: "Name", key: "name" },
        { label: "Proposal Title", key: "proposalTitle" },
        { label: "PI", key: "principle_investigator" },
        { label: "Institution", key: "institution" },
        { label: "Expertise Sought", key: "expertise" },
        { label: "Description", key: "description" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "LinkedIn", key: "linkedIn" },
        { label: "Profession", key: "profession" },
    ]

    return (
        <div>
            <GreyBox>
                <br />
                <Item sx={{textAlign: "center"}}><h1>Proposals for calls:</h1></Item>

                <div style={{ padding: '0 5vw' }}>
                    <DataTable
                        columns={columns}
                        data={requestsProposals}
                        customStyles={customStyles}
                    />
                </div>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Details</DialogTitle>
                    <DialogContent>
                        <Item><h1>Call Title: {selectedRow?.call}</h1></Item>
                        <Item><h1>User: {selectedRow?.user}</h1></Item>
                        <Item><h1>Proposal Title: {selectedRow?.proposalTitle}</h1></Item>
                        <Item><h1>Principle Investigator: {selectedRow?.principle_investigator == true ? 'Yes' : 'No' }</h1></Item>
                        <Item><h1>Description: {selectedRow?.description}</h1></Item>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* Old Card System:
                <div>
                {requestsProposals.map((item, key) => {
                return (
                <div style={{"border": "1px solid black", "width": "30%", "border-radius": "20px", "padding": "1em", "margin": "auto", "marginBottom": "1em"}}>
                    <p>{item.call}</p>
                    <p>{item.user}</p>
                    <p>{item.proposalTitle}</p>
                    <p>{item.principle_investigator == true ? 'Yes' : 'No' } {console.log("PI is " + item.principle_investigator)}</p>
                    <p>{item.description}</p>
                    {item.expertise !== undefined ? item.expertise.map((expertise) => {
                    return (
                        <p>{expertise}</p>
                    )
                    }) : <p>No expertise requirements.</p>}
                </div>
                )})}
                
                </div>
                */}

                <br />
            </GreyBox>
        </div>
    )
}

export default AdminProposalsForCalls;