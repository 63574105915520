import {Paper} from "@mui/material";

const OpaquePaper = ({ opacity = 0.8, style, children }) => {
  const paperStyle = {
    background: `rgba(255, 255, 255, ${opacity})`,
    backdropFilter: "blur(2px)",
    paddingBottom: "20px",
    ...style, // Merge additional styles passed as props
  };

  return <Paper style={paperStyle} square>{children}</Paper>;
};

export default OpaquePaper