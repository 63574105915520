import React, { useState, useEffect } from 'react';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import {Tooltip, Paper, Chip } from "@mui/material";
import { styled } from '@mui/material/styles';
import DataTable from 'react-data-table-component';
import { CircularProgress } from '@mui/material';
import { CSVLink } from "react-csv";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

const AdminStats = () => {

    const [coffeeBreaks, setCoffeeBreaks] = useState([])
    const [showCoffeeBreaks, setShowCoffeeBreaks] = useState(false)

    const [users, setUsers] = useState([])
    const [scotlandUsers, setScotlandUsers] = useState(0)
    const [germanUsers, setGermanUsers] = useState(0)
    const [showUsers, setShowUsers] = useState(false)
    const [showGermanyUsers, setShowGermanyUsers] = useState(false)
    const [showScottishUsers, setShowScottishUsers] = useState(false)

    const [getUsersIsLoading, setGetUsersIsLoading] = useState(true)
    const [getCoffeeBreaksIsLoading, setGetCoffeeBreaksIsLoading] = useState(true)
    

    useEffect(() => {
        getCoffeeBreaks()
        getUsers()
    }, [])

    const getCoffeeBreaks = () => {
        axiosInstance.get("api/coffeeBreak/GetCoffeeBreaks")
        .then((res) => {
            //console.log(res.data.coffeebreaks)
            setCoffeeBreaks(res.data.coffeebreaks)
            setGetCoffeeBreaksIsLoading(false)
        })
    }

    const getUsers = () => {
        axiosInstance.get("api/register/getUsers")
        .then((res) => {
           // console.log(res.data.users)
            setUsers(res.data.users)
            setScotlandUsers(res.data.users.filter(user => user.country === 'Scotland').length)
            setGermanUsers(res.data.users.filter(user => user.country === 'Germany').length)
            setGetUsersIsLoading(false)
        })
    }

    const toggleFunc = (funcToChange, stateToChange, optCloseFunc = null) => { 
        funcToChange(!stateToChange);
        if (optCloseFunc) {
            optCloseFunc.forEach(func => func(false))
        }
    }

    const doNothing = () => {}

    let coffeeBreakColumns = [
        {
            name: 'Created By',
            selector: row=> row.created_by.email,
            sortable: true,
            wrap: true,
        },
        {
            name: "Country",
            selector: row => row.countries.join(', '),
            sortable: true,
            wrap: true,
        },
        {
            name: "Professions",
            selector: row => row.professions.join(', '),
            sortable: true,
            wrap: true,
        },
        {
            name: "Participants",
            selector: row => row.participants,
            sortable: true,
            wrap: true,
        }
    ]

    let UserColumns = [
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
            wrap: true,
        },
        {
            name: "First Name",
            selector: row => row.first_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Last Name",
            selector: row => row.last_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Discipline",
            selector: row => row.discipline,
            sortable: true,
            wrap: true,
        },
        {
            name: "Research Network",
            selector: row => row.researchNetwork,
            sortable: true,
            wrap: true,
        },
        {
            name: "Interests",
            selector: row => row.interests,
            sortable: true,
            wrap: true,
            cell: row => (
                <>
                    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                        {row.interests.join(',').split(',').map((keyword, index) => (
                            <Tooltip key={index} title={keyword.trim()}> 
                                <Chip key={index} label={keyword.trim()} style={{margin: '2px'}} />
                            </Tooltip>
                        ))}
                    </div>
                </>
            ),
        },
    ]

    const customStyles = {
        rows: {
            style: {
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
        cells: {
            style: {
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                fontSize: '18px',
                color: 'black',
                paddingLeft: '10px',
                paddingRight: '10px',
                textAlign: 'left',
                backgroundColor: '#F5F5F5',
            },
        },
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#F5F5F5',
            },
        },
    };

    let usersHeaders = [
        { label: "Email", key: "email" },
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Admin?", key: "admin" },
        { label: "Institution", key: "institution" },
        { label: "Research Network", key: "researchNetwork" },
        { label: "Discipline", key: "discipline" },
        { label: "Interests", key: "interests" },
        { label: "Country", key: "country" },
    ]
    
    let coffeeBreakHeaders = [
        { label: "Created By: Email", key: "created_by.email" },
        { label: "Created By: First Name", key: "created_by.firstName" },
        { label: "Created By: Last Name", key: "created_by.lastName" },
        { label: "Professions", key: "professions" },
        { label: "Participants", key: "participants" },
        { label: "Country", key: "countries" },
    ]

    return (
        <div>
            <GreyBox>
                <br />
                <Item sx={{textAlign: "center"}}><h1>Statistics</h1></Item>
                
                <h2>There are {!getUsersIsLoading ? users.length : <CircularProgress size={20}/>} <span onClick={() => {!getUsersIsLoading ? toggleFunc(setShowUsers, showUsers, [setShowScottishUsers, setShowGermanyUsers]) : doNothing()}} style={{cursor: 'pointer', textDecoration: 'underline'}}>Users</span></h2>

                {showUsers &&
                    <>
                        <CSVLink 
                            data={users} 
                            filename={"All Users" + ".csv"} 
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                textDecoration: 'none',
                                outline: 'none',
                                color: '#fff',
                                backgroundColor: '#4CAF50',
                                border: 'none',
                                borderRadius: '15px',
                                boxShadow: '0 9px #999'
                            }}
                            headers={usersHeaders}
                            target='_blank'
                        >
                            Download All Users CSV
                        </CSVLink>

                        <h3>There are {scotlandUsers} <span onClick={() => toggleFunc(setShowScottishUsers, showScottishUsers)} style={{cursor: 'pointer', textDecoration: 'underline'}}>Scottish Users</span></h3>
                        {showScottishUsers &&
                            <>
                                <h4>Scottish Users</h4> 

                                <CSVLink 
                                    data={users.filter(user => user.country === 'Scotland')} 
                                    filename={"Scottish Users" + ".csv"} 
                                    style={{
                                        display: 'inline-block',
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        outline: 'none',
                                        color: '#fff',
                                        backgroundColor: '#4CAF50',
                                        border: 'none',
                                        borderRadius: '15px',
                                        boxShadow: '0 9px #999'
                                    }}
                                    headers={usersHeaders}
                                    target='_blank'
                                >
                                    Scottish Users CSV
                                </CSVLink>

                                <br/>
                                <br/>

                                <div style={{ padding: '0 5vw'}}>
                                    <DataTable
                                        columns={UserColumns}
                                        data={users.filter(user => user.country === 'Scotland')}
                                        customStyles={customStyles}
                                    />
                                </div>
                            </>
                        }

                        <h3>There are {germanUsers} <span onClick={() => toggleFunc(setShowGermanyUsers, showGermanyUsers)} style={{cursor: 'pointer', textDecoration: 'underline'}}>German Users</span></h3>
                        {showGermanyUsers &&
                            <>
                                <h3>German Users</h3>

                                <CSVLink 
                                    data={users.filter(user => user.country === 'Germany')} 
                                    filename={"German Users" + ".csv"} 
                                    style={{
                                        display: 'inline-block',
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        outline: 'none',
                                        color: '#fff',
                                        backgroundColor: '#4CAF50',
                                        border: 'none',
                                        borderRadius: '15px',
                                        boxShadow: '0 9px #999'
                                    }}
                                    headers={usersHeaders}
                                    target='_blank'
                                >
                                    German Users CSV
                                </CSVLink>

                                <br/>
                                <br/>

                                <div style={{ padding: '0 5vw'}}>
                                    <DataTable
                                        columns={UserColumns}
                                        data={users.filter(user => user.country === 'Germany')}
                                        customStyles={customStyles}
                                    />
                                </div>
                            </>
                        }
                    </>
                }

                <h2>There are {!getCoffeeBreaksIsLoading ? coffeeBreaks.length : <CircularProgress size={20}/>} <span onClick={() => {!getCoffeeBreaksIsLoading ? toggleFunc(setShowCoffeeBreaks, showCoffeeBreaks) : doNothing()}} style={{cursor: 'pointer', textDecoration: 'underline'}}> CoffeeBreaks</span></h2>
                {showCoffeeBreaks &&
                    <>
                        <CSVLink 
                            data={coffeeBreaks} 
                            filename={"Coffeebreaks" + ".csv"} 
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                textDecoration: 'none',
                                outline: 'none',
                                color: '#fff',
                                backgroundColor: '#4CAF50',
                                border: 'none',
                                borderRadius: '15px',
                                boxShadow: '0 9px #999'
                            }}
                            headers={coffeeBreakHeaders}
                            target='_blank'
                        >
                            CoffeeBreak CSV
                        </CSVLink>

                       <br/>
                       <br/>

                        <div style={{ padding: '0 5vw'}}>
                            <DataTable
                                columns={coffeeBreakColumns}
                                data={coffeeBreaks}
                                customStyles={customStyles}
                            />
                        </div>
                    </>
                }

                <br />
            </GreyBox>
        </div>
    )
}

export default AdminStats;